import React, { useEffect } from 'react';
import './BasePopup.css';
import close from '../../../img/close.png';
import { usePopupContext } from '../../../context/PopupContext';

export const BasePopup = () => {
  const {
    isActive,
    setActive,
    content,
    setWasClosedPopup,
    setWasOpenedPopupBefore,
  } = usePopupContext();

  useEffect(() => {
    setWasClosedPopup(false);
  }, []);

  const closeModal = () => {
    setActive(false);
    setWasClosedPopup(true);
    setWasOpenedPopupBefore(false);
  };

  if (isActive == true) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }

  return (
    <div
      className={isActive ? 'basePopup active' : 'modal'}
      onClick={() => setActive(false)}
    >
      <div className="bp-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="bp-btn-close-container">
          <img
            className="bp-btn-close-icon"
            alt="close"
            src={close}
            onClick={closeModal}
          />
        </div>
        <div className="popupWrapper">{content}</div>
      </div>
    </div>
  );
};
