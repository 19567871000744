import React, { useState, useEffect } from "react";
import { useState } from "react";
import { usePopupContext } from "../../../context/PopupContext";
import "./BeSpeakerPopUp.css";
import { PROXY_URL } from "../../../shared/constants";
import BC365Service from "../../../services/beautyclub365";

export default function BeSpeakerPopUp() {
  const [form, setForm] = useState({
    theme: "",
    name: "",
    tel: "",
    email: "",
    social: "",
    experienceSale: "Да",
    experienceWebinar: "Да",
    howManyPeople: "",
    expertise: "",
    experienceExpertise: "",
    education: "",
    aboutYourself: "",
    reasonWhy: "",
  });

  const onChange = (e) => {
    const { value, name, type, checked } = e.target;

    setForm((state) => ({
      ...state,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const showData = () => {
    // console.log("Form:", form);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    showData();
  };

  [ listErrors, setListErrors ] = useState([]);
  [ getAnswer, setGetAnswer ] = useState(false);

  const { 
		setActive, 
		setContent, 
	} = usePopupContext();

  useEffect(() => {
    if (getAnswer && listErrors.length === 0) {
      const closeSpeakerPopup = setTimeout(() => {
        setContent('');
				setActive(false);
        clearTimeout(closeSpeakerPopup);
      }, 3000);
    }
  }, [
    getAnswer,
    listErrors,
  ]);

  const db = new BC365Service();
  const submit = () => {
    db.createSpeakerCandidate(form).then((answer) => {
      const {
        errors,
        error_messages,
      } = answer['res'];
      setGetAnswer(true);
      if (errors === 'Y' && error_messages.length > 0) {
        setListErrors(error_messages);
      } else {
        setListErrors([]);
      }
    });
    showData();
  }

  return (
    <div className="be-speeker-pop-up-content">
      <div className="be-speeker-pop-up-header">
        <h1 className="h1-be-speeker-pop-up">Хочу стать экспертом клуба</h1>
        <h2 className="h2-be-speeker-pop-up">
          Заполните поля для заявки и наши специалисты свяжутся с Вами
        </h2>
        <p className="be-speeker-pop-up-zapolnenie-polej">
          <span id="pink-text">*</span>
          <span className="span13"> - Поля, обязательные для заполнения</span>
        </p>
      </div>
      <form onSubmit={onSubmit} className="be-speeker-pop-up-form">
        <div className="be-speeker-pop-up-right">
          <section>
            <div className="be-speeker-pop-up-dropdown">
              <select name="theme" onChange={onChange} className="be-speeker-pop-up-dropdown-select">
                <option value="">Выберите тему клуба *</option>
                <option value="Здоровье">Здоровье</option>
                <option value="Духовные/Эзотерические практики">Духовные/Эзотерические практики</option>
                <option value="Hand-made">Hand-made</option>
                <option value="Стилистика/Визаж">Стилистика/Визаж</option>
                <option value="Психология">Психология</option>
                <option value="Тренировки/Танцы">Тренировки/Танцы</option>
                <option value="Личностное развитие">Личностное развитие</option>
              </select>
            </div>
          </section>
          <input
            className="be-speeker-pop-up-input-div"
            placeholder="Ваше имя *"
            required
            name="name"
            type="text"
            pattern="/[a-z]+/i"
            onChange={onChange}
          ></input>
          <input
            className="be-speeker-pop-up-input-div tel"
            placeholder="Ваш телефон *"
            name="tel"
            type="number"
            onChange={onChange}
          ></input>
          <input
            className="be-speeker-pop-up-input-div email"
            placeholder="Ваш E-mail *"
            name="email"
            onChange={onChange}
            pattern="[a-zA-Z]{3,30}"
          ></input>
          <textarea
            className="be-speeker-pop-up-input-div be-speeker-mobile-height"
            placeholder="Ссылка на ваш профиль в соц.сети *"
            name="social"
            onChange={onChange}
          ></textarea>
          <div className="be-speeker-pop-up-group-div">
            <div className="be-speeker-pop-up-radio-div">
              <span>Есть ли опыт в продаже своих курсов и тренингов?</span>
              <span id="pink-text"> *</span>
            </div>
            <div className="be-speeker-pop-up-radio">
              <label>
                <input
                  onChange={onChange}
                  type="radio"
                  value="Да"
                  name="experienceSale"
                  checked={form.experienceSale === "Да"}
                />
                Да
              </label>
            </div>
            <div className="be-speeker-pop-up-radio">
              <label>
                <input
                  onChange={onChange}
                  type="radio"
                  value="Нет"
                  name="experienceSale"
                  checked={form.experienceSale === "Нет"}
                />
                Нет
              </label>
            </div>
          </div>

          <div className="be-speeker-pop-up-group-div">
            <div className="be-speeker-pop-up-radio-div">
              <span>Есть ли опыт в проведении вебинаров?</span>
              <span id="pink-text"> *</span>
            </div>
            <div className="be-speeker-pop-up-radio">
              <label>
                <input
                  onChange={onChange}
                  type="radio"
                  value="Да"
                  name="experienceWebinar"
                  checked={form.experienceWebinar === "Да"}
                />
                Да
              </label>
            </div>
            <div className="be-speeker-pop-up-radio">
              <label>
                <input
                  onChange={onChange}
                  type="radio"
                  value="Нет"
                  name="experienceWebinar"
                  checked={form.experienceWebinar === "Нет"}
                />
                Нет
              </label>
            </div>
            <div className="be-speeker-pop-up-radio">
              <label>
                <input
                  onChange={onChange}
                  type="radio"
                  value="Немного"
                  name="experienceWebinar"
                  checked={form.experienceWebinar === "Немного"}
                />
                Немного
              </label>
            </div>
          </div>
        </div>

        <div className="be-speeker-pop-up-left">
          <textarea
            className="be-speeker-pop-up-input-div be-speeker-mobile-height"
            placeholder="Сколько людей прошло ваше обучение/курсы/наставничество? *"
            onChange={onChange}
            name="howManyPeople"
          ></textarea>
          <textarea
            className="be-speeker-pop-up-input-div"
            style={{ height: "218px" }}
            placeholder="Опишите в чем ваша экспертность *"
            onChange={onChange}
            name="expertise"
          ></textarea>
          <textarea
            className="be-speeker-pop-up-input-div be-speeker-mobile-height"
            placeholder="Сколько лет транслируете свою экспертность? *"
            onChange={onChange}
            name="experienceExpertise"
          ></textarea>
          <textarea
            className="be-speeker-pop-up-input-div be-speeker-mobile-height"
            placeholder="Наличие профильного образования (указать какое) *"
            onChange={onChange}
            name="education"
          ></textarea>
          <textarea
            className="be-speeker-pop-up-input-div be-speeker-mobile-height"
            placeholder="Расскажите немного о себе *"
            onChange={onChange}
            name="aboutYourself"
          ></textarea>
          <textarea
            className="be-speeker-pop-up-input-div be-speeker-mobile-height"
            placeholder="Почему хотите стать экспертом в клубе BeautyClub365? *"
            onChange={onChange}
            name="reasonWhy"
          ></textarea>
        </div>
      </form>
      {
        listErrors.length > 0 && (
        <div className="speakerAddErrors">
          <p>Исправьте следующие ошибки:</p>
          <ul>
            {listErrors.map((error, index) => {
              return (
                <li key={index}>{error}</li>
              )
            })}
          </ul>
        </div>
        )
      }
      {
        getAnswer && listErrors.length === 0 && (
        <div className="speakerAddSuccess">
          <p>Ваши данные успешно отправлены</p>
        </div>
        )
      }
      <div className="btn-be-speeker-pop-up-container">
        <button onClick={submit} className="btn-be-speeker-pop-up">
          <div className="inter-medium-white-14px">
            Хочу стать спикером клуба
          </div>
        </button>
        <p className="be-speaker-policy-p">
          Нажимая "Хочу стать спикером клуба" я даю согласие на обработку моих
          персональных данных и принимаю условия{" "}
          <a href={PROXY_URL + "/terms-conditions/"}>
            политики конфиденциальности
          </a>
        </p>
      </div>
    </div>
  );
}
