CertificateBlock.jsx;

import React from "react";
import { usePopupContext } from "../../context/PopupContext";
import { useUserContext } from "../../context/UserContext";
import CheckEmailCertificate from "../Forms/CheckEmailCertificate/CheckEmailCertificate";
import AllCertificates from "../Forms/AllCertificates/AllCertificates";
import "./CertificateBlock.css";

function CertificateBlock() {
  const { setActive, setContent } = usePopupContext();
  const { userProfile, setIsCertificate } = useUserContext();
  const buyCertificate = () => {
    const { online } = userProfile;
    setActive(true);
    if (online) {
      setIsCertificate(true);
      setContent(<AllCertificates />);
    } else {
      setContent(<CheckEmailCertificate />);
    }
  };

  return (
    <div className="certificate_block">
      <div className="certificate-container">
        <div className="certificate-block-content">
          <h1 className="h1-certificate-container montserrat-extra-bold-hollywood-cerise-48px">
            Подарите сертификат на подписку в клуб близкому человеку
          </h1>
          <h2 className="h2-certificate-container inter-normal-mischka-24px">
            Отличный способ порадовать мам, дочерей, бабушек и подруг.
          </h2>
          <button className="certificate_block-btn" onClick={buyCertificate}>
            ПОДАРИТЬ СЕРТИФИКАТ
          </button>
        </div>
        <img
          className="sert_ill"
          src={require("../../img/MainContent/certificate-block.svg")}
          alt=""
        />
      </div>
    </div>
  );
}

export default CertificateBlock;
