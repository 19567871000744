import React, { useState } from "react";
import Loading from "../Loader/Loader";
import Speaker from "../Popups/Speaker/Speaker";
import { usePopupContext } from "../../context/PopupContext";
import "./ExpertsBlock.css";

function ExpertsBlock(props) {

  const {
    innoRef,
    speakersList,
    waitingMoreSpeakers,
    speakersCount,
  } = props;

  const { setActive, setContent } = usePopupContext();

  const [showMoreButton, setShowMoreButton] = useState(true);

  let {
    total,
    setTotal,
  } = props;

  const loadMoreSpeakers = () => {
    total += 3;
    setTotal(total);
    if (total >= speakersCount) {
      setShowMoreButton(false);
    }
  };

  const showSpeakerDetailInfo = (e, photo, text, name, previewText) => {
    e.preventDefault();
    setContent('');
    setActive(true);
    setContent(
      <Speaker 
        photo={photo}
        text={text}
        name={name}
        previewText={previewText}
      />
    );
  };

  return (
    <div className="experts_block" id="experts_block">
      <div ref={innoRef} className="experts-pointBlock"></div>
      <h1 className="text-36 montserrat-extra-bold-stratos-48px">
        Поменять жизнь вам помогут топовые эксперты из разных сфер
      </h1>
      <div className="box-experts_block">
        {
          speakersList.length > 0 && speakersList.map((speaker, key) => {
            return (
              <div className="box" key={'speaker' + key}>
                <div className="image-experts_block">
                  <img src={speaker.photo} alt="" />
                </div>
                <div className="content">
                  <h3 className="montserrat-extra-bold-stratos-18px">{speaker.name}</h3>
                  <p className="inter-normal-stratos-14px">
                    {speaker.previewText}
                  </p>
                  <a href="" className="btn-experts_block" onClick={
                    (e) => showSpeakerDetailInfo(e, speaker.detailhoto, speaker.detailText, speaker.name, speaker.previewText)
                  }>
                    о спикере
                  </a>
                </div>
              </div>
            )
          })
        }
      </div>
      <div>
        {waitingMoreSpeakers && <Loading width="100" />}
      </div>

      {!waitingMoreSpeakers && showMoreButton && <button id="load-more" onClick={loadMoreSpeakers}>Показать больше спикеров</button>}
    </div>
  );
}

export default ExpertsBlock;
