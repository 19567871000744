import React, { useState, useEffect, useRef } from "react";
import HeaderInfo from "../../components/HeaderInfo/HeaderInfo";
import Header from "../../components/Header/Header";
import WhatYouHaveBlock from "../../components/WhatYouHaveBlock/WhatYouHaveBlock";
import WhatYouGetBlock from "../../components/WhatYouGetBlock/WhatYouGetBlock";
import SecretOfSuccessBlock from "../../components/SecretOfSuccessBlock/SecretOfSuccessBlock";
import ChangeLifeBlock from "../../components/ChangeLifeBlock/ChangeLifeBlock";
import IndividualProgrammBlock from "../../components/IndividualProgrammBlock/IndividualProgrammBlock";
import ResultsBlock from "../../components/ResultsBlock/ResultsBlock";
import MotivationBlock from "../../components/MotivationBlock/MotivationBlock";
import PaymentBlock from "../../components/PaymentBlock/PaymentBlock";
import CommunitySupportBlock from "../../components/CommunitySupportBlock/CommunitySupportBlock";
import ExpertsBlock from "../../components/ExpertsBlock/ExpertsBlock";
import ScheduleBlock from "../../components/ScheduleBlock/ScheduleBlock";
import CommunityBlock from "../../components/CommunityBlock/CommunityBlock";
import CertificateBlock from "../../components/CertificateBlock/CertificateBlock";
import BeSpeakerBlock from "../../components/BeSpeakerBlock/BeSpeakerBlock";
import Footer from "../../components/Footer/Footer";
import btnUp from "../../img/btn-up.png";
import BC365Service from "../../services/beautyclub365";
import Loading from "../loading/loading";
import { BasePopup } from "../../components/Popups/Base/BasePopup";
import { useUserContext } from "../../context/UserContext";
import { usePopupContext } from '../../context/PopupContext';
import { isMobile } from "react-device-detect";
import "./main.css";
import footer from "../../components/Footer/Footer";
import VideoReview from "../../components/VideoReview/VideoReview";
import PromotionLine from '../../components/PromotionLine/PromotionLine'

const Main = (props) => {
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [isLoadingSpeakers, setIsLoadingSpeakers] = useState(true);
  const [isLoadingShedule, setIsLoadingShedule] = useState(true);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [headerContent, setHeaderContent] = useState({});
  const [headerGroupContent, setHeaderGroupContent] = useState({});
  const [popupFreeTestDriveContent, setPopupFreeTestDriveContent] = useState(
    {}
  );
  const [clubForYouContent, setClubForYouContent] = useState({});
  const [allForYouContent, setAllForYouContent] = useState({});
  const [daysFreeContent, setDaysFreeContent] = useState({});
  const [successSecretsContent, setSuccessSecretsContent] = useState({});
  const [changeYourLifeContent, setChangeYourLifeContent] = useState({});
  const [individualProgrammContent, setIndividualProgrammContent] = useState(
    {}
  );
  const [speakersList, setSpeakersList] = useState([]);
  const [shedule, setShedule] = useState({});
  const [speakersCount, setSpeakersCount] = useState(0);

  const [waitingMoreSpeakers, setWaitingMoreSpeakers] = useState(false);

  let count = isMobile ? 3 : 6;
  let [total, setTotal] = useState(count);

  // set refs
  const ref = useRef(null);
  const WhatYouHaveBlockRef = useRef(null);
  const WhatYouGetBlockRef = useRef(null);
  const SecretOfSuccessRef = useRef(null);
  const ChangeLifeRef = useRef(null);
  const ResultsRef = useRef(null);
  const PaymentRef = useRef(null);
  const ExpertsRef = useRef(null);
  const ScheduleRef = useRef(null);

  const goByAnchor = () => {
    const anchor = window.location.hash;
  };

  goByAnchor();

  const {
    userProfile,
    setUserProfile,
    setProducts,
    setDefaultPrice,
    setShowTestDrive,
    setUserCanUseTrial,
    menuRef,
    setMenuRef,
  } = useUserContext();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleClick = () => {
    if (ref.current) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (menuRef !== '') {
        switch (menuRef) {
          case 'WhatYouHaveBlockRef': 
            WhatYouHaveBlockRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
            break;
          case 'WhatYouGetBlockRef': 
            WhatYouGetBlockRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
            break;
          case 'SecretOfSuccessRef': 
            SecretOfSuccessRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
            break;
          case 'ChangeLifeRef': 
            ChangeLifeRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
            break;
          case 'ResultsRef': 
            ResultsRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
            break;
          case 'PaymentRef': 
            PaymentRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
            break;
          case 'ExpertsRef': 
            ExpertsRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
            break;
          case 'ScheduleRef': 
            ScheduleRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
            break;
        }
      }
      setMenuRef('');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const goToFromMenu = (e, r, t) => {
    e.preventDefault();
    if (r.current) {
      r.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const db = new BC365Service();

  useEffect(() => {
    db.setStat().then((answer) => {
      // console.log(answer);
    });
  }, []);

  useEffect(() => {
    // get products
    setIsLoadingProducts(true);
    db.getProducts().then((answer) => {
      setIsLoadingProducts(false);
      if (answer["products"].length > 0) {
        setDefaultPrice(answer["products"][0].price);
        const productPairs = answer["products"].reduce(
          (result, value, index, array) => {
            if (index % 2 === 0) {
              result.push(array.slice(index, index + 2));
            }
            return result;
          },
          []
        );
        setProducts(productPairs);
      }
    });
  }, [setProducts, setDefaultPrice, setIsLoadingProducts]);

  useEffect(() => {
    // get speakers
    setWaitingMoreSpeakers(true);
    db.getSpeakers(total).then((answer) => {
      const speakersContent = db.getMappedData(answer["res"], "speakers");
      if (speakersContent["result"].length > 0) {
        setSpeakersList(speakersContent["result"]);
        setSpeakersCount(speakersContent["total"]);
      }
      if (speakersContent["result"].length > 0) {
        setIsLoadingSpeakers(false);
      }
      setWaitingMoreSpeakers(false);
    });
  }, [setSpeakersList, setWaitingMoreSpeakers, setSpeakersCount, total]);

  useEffect(() => {
    // get shedule
    db.getShedule().then((answer) => {
      const sheduleContent = db.getMappedDataByCode(answer, "shedule");
      setShedule(sheduleContent);
      setIsLoadingShedule(false);
    });
  }, [setShedule, setIsLoadingShedule]);

  useEffect(() => {
    // is Online?
    db.isOnline().then((answer) => {
      let token = answer["is_online"] ? answer["session_id"] : "";
      setUserProfile({
        fio: answer["user_data"]["name"],
        email: answer["user_data"]["email"],
        avatar: answer["user_data"]["photo"],
        login: answer["user_data"]["login"],
        token: token,
        online: answer["is_online"],
        user_id: answer["user_data"]["user_id"],
        phone: "",
        docs_prefix: answer["docs_prefix"],
      });

      if (answer["user_data"]["user_id"] > 0) {
        db.canGetTrial().then((answer) => {
          const { result } = answer;
          setShowTestDrive(result);
          setUserCanUseTrial(result);
        });
      }
      setIsLoadingUserData(false);
    });

    db.getContent().then((answer) => {
      const headerContent = db.getCurrentData(answer, "header");
      if (headerContent !== {}) {
        setHeaderContent(headerContent);
      }
      const headerGroupContent = db.getCurrentData(answer, "header-group");
      if (headerGroupContent !== {}) {
        setHeaderGroupContent(headerGroupContent);
      }
      const popupFreeTestDriveContent = db.getCurrentData(
        answer,
        "popup-free-test-drive"
      );
      if (popupFreeTestDriveContent !== {}) {
        setPopupFreeTestDriveContent(popupFreeTestDriveContent);
      }
      const clubForYouContent = db.getCurrentData(answer, "club-for-you");
      if (clubForYouContent !== {}) {
        setClubForYouContent(clubForYouContent);
      }
      const allForYouContent = db.getCurrentData(answer, "all-for-you");
      if (allForYouContent !== {}) {
        setAllForYouContent(allForYouContent);
      }
      const daysFreeContent = db.getCurrentData(answer, "5-days-free");
      if (daysFreeContent !== {}) {
        setDaysFreeContent(daysFreeContent);
      }
      const successSecretsContent = db.getCurrentData(
        answer,
        "success-secrets"
      );
      if (successSecretsContent !== {}) {
        setSuccessSecretsContent(successSecretsContent);
      }
      const changeYourLifeContent = db.getCurrentData(
        answer,
        "change-your-life"
      );
      if (changeYourLifeContent !== {}) {
        setChangeYourLifeContent(changeYourLifeContent);
      }
      const individualProgrammContent = db.getCurrentData(
        answer,
        "individual-programm"
      );
      if (individualProgrammContent !== {}) {
        setIndividualProgrammContent(individualProgrammContent);
      }

      if (
        Object.keys(headerContent).length > 0 &&
        Object.keys(headerGroupContent).length > 0 &&
        Object.keys(popupFreeTestDriveContent).length > 0 &&
        Object.keys(clubForYouContent).length > 0 &&
        Object.keys(allForYouContent).length > 0 &&
        Object.keys(daysFreeContent).length > 0 &&
        Object.keys(successSecretsContent).length > 0 &&
        Object.keys(changeYourLifeContent).length > 0 &&
        Object.keys(individualProgrammContent).length > 0
      ) {
        setIsLoadingContent(false);
      }
    });
  }, [
    setHeaderContent,
    setIsLoadingContent,
    setPopupFreeTestDriveContent,
    setClubForYouContent,
    setAllForYouContent,
    setDaysFreeContent,
    setSuccessSecretsContent,
    setChangeYourLifeContent,
    setIndividualProgrammContent,
    setUserProfile,
  ]);

  const { 
    setActive, 
    setContent,
  } = usePopupContext();

  const joinToUs = () => {
    const { online } = userProfile;
    setActive(true);
    if (online) {
      window.location.assign('/personal/subscription-feed/');
    } else {
      setContent(<CheckEmail />);
    }
  };

  const {
    resultsBlockProps,
    motivationBlockProps,
    communitySupportBlockProps,
    expertsBlockProps,
    scheduleBlockProps,
    certificateBlockProps,
    beSpeakerBlockProps,
    footerProps,
    x1Props,
    whatYouGetBlockProps,
    secretOfSuccessBlockProps,
    individualProgrammBlockProps,
  } = props;

  return !isLoadingContent &&
    !isLoadingSpeakers &&
    !isLoadingUserData &&
    !isLoadingShedule ? (
    <>
      <div className="bc365" ref={ref}>
        {/* <SnowFlakes /> */}
        <PromotionLine />
        <BasePopup />
        <Header
          {...Object.assign({
            goToFromMenu,
            WhatYouHaveBlockRef,
            WhatYouGetBlockRef,
            SecretOfSuccessRef,
            ChangeLifeRef,
            ResultsRef,
            PaymentRef,
            ExpertsRef,
            ScheduleRef,
            scrollPosition,
          })}
        />
        <HeaderInfo
          {...Object.assign({
            x1Props,
            headerContent,
          })}
        />
        <WhatYouHaveBlock
          {...Object.assign(
            { innoRef: WhatYouHaveBlockRef },
            clubForYouContent
          )}
        />
        <WhatYouGetBlock
          {...Object.assign({ innoRef: WhatYouGetBlockRef }, allForYouContent)}
          whatyouget_Block={whatYouGetBlockProps.whatyouget_Block}
        />
        <SecretOfSuccessBlock
          {...Object.assign(
            { innoRef: SecretOfSuccessRef },
            successSecretsContent,
            secretOfSuccessBlockProps
          )}
        />
        <ChangeLifeBlock {...changeYourLifeContent} innoRef={ChangeLifeRef} />
        <IndividualProgrammBlock
          image20={individualProgrammBlockProps.image20}
          spanText={individualProgrammBlockProps.spanText}
          x1={individualProgrammBlockProps.x1}
          x2={individualProgrammBlockProps.x2}
          {...individualProgrammContent}
        />
        <ResultsBlock {...resultsBlockProps} innoRef={ResultsRef} />
        <CommunitySupportBlock {...communitySupportBlockProps} />
        <MotivationBlock {...motivationBlockProps} />
        <PaymentBlock innoRef={PaymentRef} />
        <ExpertsBlock
          {...expertsBlockProps}
          speakersList={speakersList}
          innoRef={ExpertsRef}
          total={total}
          setTotal={setTotal}
          setWaitingMoreSpeakers={setWaitingMoreSpeakers}
          waitingMoreSpeakers={waitingMoreSpeakers}
          speakersCount={speakersCount}
        />
        <ScheduleBlock
          {...scheduleBlockProps}
          innoRef={ScheduleRef}
          shedule={shedule}
        />
        <VideoReview />
        <CommunityBlock />
        <CertificateBlock
          rectangle38={certificateBlockProps.rectangle38}
          spanText1={certificateBlockProps.spanText1}
          spanText2={certificateBlockProps.spanText2}
          spanText3={certificateBlockProps.spanText3}
          group={certificateBlockProps.group}
          image23={certificateBlockProps.image23}
        />
        <BeSpeakerBlock {...beSpeakerBlockProps} />
        <Footer {...footerProps} docsPrefix={userProfile.docs_prefix} />
      </div>
      {scrollPosition > 100 && (
        <button onClick={handleClick} href="">
          <img className="btn-up" src={btnUp} alt="наверх" />
        </button>
      )}
      {/* {scrollPosition > 500 && (
        <button className="testDrive_btn_fixed" onClick={joinToUs}>
          <div className="testDrive_btn-text">
            <div className="btn-text-bold inter-black-white-24px">
              Присоединиться
            </div>
          </div>
        </button>
      )} */}
    </>
  ) : (
    <Loading />
  );
};

export default Main;
