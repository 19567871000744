import React from "react";
import { getImagesPaths } from "../../shared/utils";
import "./IndividualProgrammBlock.css";

function IndividualProgrammBlock(props) {
  const { 
    individualProgrammMainText,
    individualProgrammImages,
   } = props;

   // prepare images
  let contentImages = getImagesPaths(individualProgrammImages);

  return (
    <div className="individual-programm_block">
      <div className="overlap-group10">
        <img className="image-20" src={contentImages['main']} alt="image 20" />
        <div className="text-72 montserrat-extra-bold-stratos-48px">
          Составьте индивидуальную программу и работайте над своим запросом
          комплексно
        </div>
        <img className="x1-1" src={contentImages['im1']} alt="1" />
        <img className="x2" src={contentImages['im2']} alt="2" />
      </div>
    </div>
  );
}

export default IndividualProgrammBlock;
