import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../../components/Header/Header";
import { BasePopup } from "../../components/Popups/Base/BasePopup";
import btnUp from "../../img/btn-up.png";
import "./courses.css";
import Back from "../../img/337-ctrl.svg";
import Filter from "../../img/filter.svg";
import Sort from "../../img/sort.svg";
import { PROXY_URL } from "../../shared/constants";
import { usePopupContext } from "../../context/PopupContext";
import { useUserContext } from "../../context/UserContext";
import Loader from "../../components/Loader/Loader";
import BC365Service from "../../services/beautyclub365";
import logo from "../../img/logo black.png";
import { prepareTextForWeek, prepareSorting, prepareFilter } from "../../shared/utils";
import { PROXY_URL, COUNT_COURSES_PER_PAGE } from "../../shared/constants";

export default function Сourses() {

  const {
    setUserProfile,
    setDefaultPrice,
    setProducts,
    setMenuRef,
  } = useUserContext();


  let history = useHistory (); 
  const goToFromMenu = (e, r, t) => {
    setMenuRef(t);
    history.push('/');
    e.preventDefault();
  };

  // set refs
  const ref = useRef(null);
  const WhatYouHaveBlockRef = useRef(null);
  const WhatYouGetBlockRef = useRef(null);
  const SecretOfSuccessRef = useRef(null);
  const ChangeLifeRef = useRef(null);
  const ResultsRef = useRef(null);
  const PaymentRef = useRef(null);
  const ExpertsRef = useRef(null);
  const ScheduleRef = useRef(null);

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [total, setTotal] = useState(0);
  const [showMoreButton, setShowMoreButton] = useState(true);
  const [loadAgain, setLoadAgain] = useState(false);
  const [updateSort, setUpdateSort] = useState(false);
  let [page, setPage] = useState(1);
  
  // default sort by price from chipper to up
  let [sort, setSort] = useState({
    price: 'asc',
  });

  // set filter
  let [filter, setFilter] = useState({});

  const db = new BC365Service();
  useEffect(() => {
    // is Online?
    db.isOnline().then((answer) => {
      let token = answer["is_online"] ? answer["session_id"] : "";
      setUserProfile({
        fio: answer["user_data"]["name"],
        email: answer["user_data"]["email"],
        avatar: answer["user_data"]["photo"],
        login: answer["user_data"]["login"],
        token: token,
        online: answer["is_online"],
        user_id: answer["user_data"]["user_id"],
        phone: "",
        docs_prefix: answer["docs_prefix"],
      });
      setIsLoadingUserData(false);
    });
  }, [
    setUserProfile,
  ]);

  useEffect(() => {
    const sortPath = prepareSorting(sort);
    const filterPath = prepareFilter(filter);
    db.getCourses('y', filterPath, sortPath, page, page * COUNT_COURSES_PER_PAGE).then((answer) => {
      const { courses, total } = answer;
      if (filterPath === '') {
        const { for_filter } = answer;
        setFilterData(for_filter);
      }
      
      setTotal(total);
      const allCourses = courses;
      setUpdateSort(false);
      setCourses(allCourses);
      setIsLoadingCourses(false);
      setLoadAgain(false);
      if (total <= page * COUNT_COURSES_PER_PAGE) {
        setShowMoreButton(false);
      }

    });
  }, [
    setFilterData,
    setCourses,
    setTotal,
    page,
    sort,
    updateSort,
  ]);


  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const handleClick = () => {
    if (ref.current) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const [x, setX] = useState(false);
  const { setActive, setContent } = usePopupContext();
  const { userProfile, setIsCertificate, setIsCourse, setTypeOfProduct } = useUserContext();
  

  const joinToUs = useCallback((index) => {
    const { online } = userProfile;
    setActive(true);
    // it's course
    setIsCourse(true);
    setTypeOfProduct(1);

    // set products
    setDefaultPrice(courses[index].discount_price);
    courses[index]['id'] = Number(courses[index].product_id);
    courses[index]['clear_price'] = courses[index].discount_price;
    setProducts([[courses[index]]]);

    if (online) {
      setIsCertificate(false);
      setContent(<AllProducts />);
    } else {
      setContent(<CheckEmail />);
    }
  }, [
    courses,
    userProfile,
  ]);

  const showDetails = useCallback((index) => {
    

    const currentCourse = courses[index];
    const { desc, name } = currentCourse;
    const content = (
      <>
        <h1 className="courseContentTitle">{name}</h1>
        <p className="courseContent">{desc}</p>
      </>
    );
    if (desc !== '') {
      setActive(true);
      setContent(content);
    }
  }, [
    courses,
  ]);

  const getMore = useCallback(() => {
    const nextPage = page + 1;
    if (total >= nextPage) {
      setIsLoadingCourses(true);
    }
    if (total <= nextPage * COUNT_COURSES_PER_PAGE) {
      setShowMoreButton(false);
    }
  }, [
    total,
    page,
  ]);

  const updateSorting = useCallback((e) => {
    setIsLoadingCourses(true);
    const { value } = e.target;
    const sortList = value.split('=');
    let sortObj = {};
    if (sortList[1] !== 'def') {
      sortObj[sortList[0]] = sortList[1];
      const updatedSort = {...sort, ...sortObj};
      setSort(updatedSort);
    } else {
      delete sort[sortList[0]];
      if (Object.keys(sort).length === 0) {
        sort = {
          price: 'asc',
        };
      }
      setSort(sort);
    }
    setLoadAgain(true);
    setPage(1);
    setShowMoreButton(true);
    setUpdateSort(true);
  }, [
    sort,
  ]);

  const updateFilter = useCallback((e) => {
    const { value } = e.target;
    let filterObj = {};
    const type = e.target.getAttribute('data-type');
    if (type === 'only-with-discounts') {
      setX(!x);
      if (value !== 'true') {
        filterObj['discount_exists'] = 1;
      } else {
        delete filter['discount_exists'];
      }
    }
    if (type === 'min-price') {
      if (value !== '') {
        filterObj['price_from'] = value;
      } else {
        delete filter['price_from'];
      }
    }
    if (type === 'max-price') {
      if (value !== '') {
        filterObj['price_to'] = value;
      } else {
        delete filter['price_to'];
      }
    }
    if (type !== 'only-with-discounts' && type !== 'min-price' && type !== 'max-price') {
      const filterList = value.split('=');
      if (filterList[1] !== 'def') {
        filterObj[filterList[0]] = filterList[1];
      } else {
        delete filter[filterList[0]];
      }
    }
    const updatedFilter = {...filter, ...filterObj};
    setFilter(updatedFilter);
    setLoadAgain(true);
    setPage(1);
    if (courses.length === 0) {
      setShowMoreButton(false);
    } else {
      setShowMoreButton(true);
    }
  }, [
    filter,
    x,
    showMoreButton,
  ]);

  const refInputMinPrice = useRef('');
  const refInputMaxPrice = useRef('');
  const refThemes = useRef('themes=def');
  const refDurations = useRef('week_duration=def');
  const refSpeakers = useRef('speakers=def');

  const findCourses = useCallback((e) => {
    setIsLoadingCourses(true);
    const oldCourses = courses;
    const sortPath = prepareSorting(sort);
    let filterPath = prepareFilter(filter);
    const type = e.target.getAttribute('data-type');
    if (type === 'reset') {
      filterPath = '';
      setFilter({});

      // reset form fields
      refInputMinPrice.current.value = '';
      refInputMaxPrice.current.value = '';
      refThemes.current.value = 'themes=def';
      refDurations.current.value = 'week_duration=def';
      refSpeakers.current.value = 'speakers=def';
      setX(false);
    }
    db.getCourses('y', filterPath, sortPath, page, page * COUNT_COURSES_PER_PAGE).then((answer) => {
      const { courses, total } = answer;
      setTotal(total);
      const allCourses = courses;
      setCourses(allCourses);
      setIsLoadingCourses(false);
      setLoadAgain(false);
      if (total <= page * COUNT_COURSES_PER_PAGE) {
        setShowMoreButton(false);
      }
    });
  }, [
    filter,
    sort
  ]);

  return (
    <>
      <div className="bc365" ref={ref}>
        <BasePopup />
        <Header
          {...Object.assign({
            goToFromMenu,
            WhatYouHaveBlockRef,
            WhatYouGetBlockRef,
            SecretOfSuccessRef,
            ChangeLifeRef,
            ResultsRef,
            PaymentRef,
            ExpertsRef,
            ScheduleRef,
            scrollPosition,
          })}
        />
          <div className="courses-content">
            <div className="courses-back-mini-button">
              <img
                className="courses-back-mini-btn"
                src={Back}
                alt="back-mini-button"
              />
              <Link className="courses-back-button" to="/">Назад</Link>
            </div>
            <div className="courses-h1-pink-text">Курсы</div>
            <div className="courses-filter-sort">
              <div className="courses-filter-button-icon">
                <img
                  className="courses-filter-btn"
                  src={Filter}
                  alt="filter-button"
                />
                <div className="courses-filter-button-text">Фильтр</div>
              </div>
              <div className="courses-product-sort">
                <p className="courses-product-sort-elem">Сортировать:</p>

                <select className="courses-product-sort-elem-select" onChange={updateSorting}>
                  <option value="price=def">
                  ↑↓ По цене
                  </option>
                  <option value="price=asc" name="price">
                    Сначала дешевле
                  </option>
                  <option value="price=desc" name="price">
                    Сначала дороже
                  </option>
                </select>

                <select className="courses-product-sort-elem-select" onChange={updateSorting}>
                  <option value="duration=def">
                  ↑↓ По длительности
                  </option>
                  <option value="duration=asc" name="duration">
                    Сначала короткие
                  </option>
                  <option value="duration=desc" name="duration">
                    Сначала длинные
                  </option>
                </select>
              </div>
            </div>

            <div className="courses-container">
              <div className="courses-filter">
                <section className="courses-filter-box-dropdown-group">
                  {Object.keys(filterData).length > 0 && (
                  <select className="courses-filter-box-dropdown" onChange={updateFilter} ref={refThemes}>
                    <option value="themes=def">Тема</option>
                    {
                      Object.entries(filterData['themes']).map(([key, value]) => {
                          return (
                            <option value={`themes=${value}`} name="theme" key={key}>{key}</option>
                          );
                      })
                    }
                  </select>
                  )}
                  {Object.keys(filterData).length === 0 && (
                  <select className="courses-filter-box-dropdown" ref={refThemes}>
                    <option value="themes=def">Тема</option>
                  </select>
                  )}

                  {Object.keys(filterData).length > 0 && (
                  <select className="courses-filter-box-dropdown" onChange={updateFilter} ref={refDurations}>
                    <option value="week_duration=def">Длительность</option>
                    {
                      filterData['durations'].map((value, key) => {
                          return (
                            <option value={`week_duration=${value}`} name="duration" key={key}>{value} {prepareTextForWeek(Number(value))}</option>
                          );
                      })
                    }
                  </select>
                  )}
                  {Object.keys(filterData).length === 0 && (
                  <select className="courses-filter-box-dropdown" ref={refDurations}>
                    <option value="week_duration=def">Длительность</option>
                  </select>
                  )}
                  {Object.keys(filterData).length > 0 && (
                    <select className="courses-filter-box-dropdown" onChange={updateFilter} ref={refSpeakers}>
                      <option value="speakers=def">Спикеры</option>
                      {
                        Object.entries(filterData['speakers']).map(([key, value]) => {
                            return (
                              <option value={`speakers=${value}`} name="speaker" key={key}>{key}</option>
                            );
                        })
                      }
                    </select>
                  )}
                  {Object.keys(filterData).length === 0 && (
                    <select className="courses-filter-box-dropdown" ref={refSpeakers}>
                      <option value="speakers=def">Спикеры</option>
                    </select>
                  )}
                </section>
                

                <div className="courses-filter-h3">Цена</div>
                <div className="courses-filter-price">
                  {
                    Object.keys(filterData).length > 0 ? (
                      <input
                        type="number"
                        placeholder={filterData['min_price'] + ' руб.'}
                        className="courses-filter-price-input"
                        onChange={updateFilter}
                        data-type="min-price"
                        ref={refInputMinPrice}
                      />
                    ) : (
                      <input
                        type="number"
                        className="courses-filter-price-input"
                        onChange={updateFilter}
                        data-type="max-price"
                        ref={refInputMinPrice}
                      />
                    )
                  }
                  <p className="defis-icon"></p>
                  {
                    Object.keys(filterData).length > 0 ? (
                      <input
                        type="number"
                        placeholder={filterData['max_price'] + ' руб.'}
                        className="courses-filter-price-input"
                        onChange={updateFilter}
                        data-type="max-price"
                        ref={refInputMaxPrice}
                      />
                    ) : (
                      <input
                        type="number"
                        className="courses-filter-price-input"
                        onChange={updateFilter}
                        data-type="max-price"
                        ref={refInputMaxPrice}
                      />
                    )
                  }
                </div>
                <div className="courses-filter-show-sale-container">
                  <input
                    type="checkbox"
                    checked={x}
                    value={x}
                    className="courses-filter-show-sale-checkbox"
                    onChange={updateFilter}
                    data-type="only-with-discounts"

                  ></input>
                  <label
                    htmlFor="courses-filter-show-sale-checkbox"
                    className="courses-filter-show-sale"
                  >
                    Показать курсы по скидке
                  </label>
                </div>
                <button 
                  className={(Object.keys(filterData).length > 0 && !isLoadingCourses) ? "courses-filter-btn-apply" : "courses-filter-btn-apply course-disabled-apply"}
                  onClick={findCourses}
                  >
                    применить
                </button>
                <button 
                  className={(Object.keys(filterData).length > 0 && !isLoadingCourses) ? "courses-filter-btn-reset" : "courses-filter-btn-reset course-disabled-reset"}
                  onClick={findCourses}
                  data-type="reset"
                  >
                    сбросить
                </button>
              </div>
              {!isLoadingUserData && !isLoadingCourses ? (
              <div className="courses-products-container">
                <div className="courses-products">
                  
                  {courses.map((course, index) => {
                    return(
                      <div className="courses-product" key={course.id}>
                        <div className="courses-product-info">
                          <img className="courses-product-img" src={PROXY_URL + course['image']} alt="img" />
                          <div className="courses-product-info-text">
                            <div className="courses-product-h1">
                              {course['name']}
                            </div>
                            <div className="courses-product-speaker-name">
                              Спикеры: {
                              course['speakers'].map((speaker, index) => {
                                const sep = (index !== course['speakers'].length - 1) ? ', ' : '';
                                return speaker['name'] + sep;
                              })
                              }
                            </div>
                            <div className="courses-product-theme">
                              Тема: {
                              course['themes'].map((theme, index) => {
                                const sep = (index !== course['themes'].length - 1) ? ', ' : '';
                                return theme['name'] + sep;
                              })
                              }
                            </div>
                            <div className="courses-product-duration">
                              Длительность: {course['week_duration']} {prepareTextForWeek(Number(course['week_duration']))}
                            </div>
                            <div className="courses-product-exercises">
                              Количество упражнений: {course['exercises_count']}
                            </div>
                            <div className="courses-product-price">{course['discount_price']} руб.</div>
                          </div>
                        </div>
                        <div className="courses-product-btn-group">
                          <button
                            className="courses-product-btn-buy"
                            onClick={() => joinToUs(index)}
                          >
                            Купить
                          </button>
                          <a href={PROXY_URL + '/market/cours/' + course['id']}>
                            <button className="courses-product-btn-podrobnee">
                                Подробнее
                            </button>
                          </a>
                        </div>
                      </div>
                    )
                  })}
                  {courses.length === 0 && (
                    <div className="coursesNotFound">
                      <p>По вашему запросу ничего не найдено.</p> 
                      <p>Попробуйте сбросить фильтр или изменить параметры</p>
                    </div>
                  )}
                </div>
                {showMoreButton && (
                <button className="courses-product-load-more-btn" onClick={() => getMore()}>
                  Загрузить ещё
                </button>
                )}
              </div>) : (
                <div className="loaderWrapper">
                  <div>
                    <img className="logo-icon-loading" src={logo} alt="logo full" />
                  </div>
                  <Loader width="100" />
                </div>
              
              )}
            </div>
          </div>
        
      </div>
      {scrollPosition > 100 && (
        <button onClick={handleClick} href="">
          <img className="btn-up" src={btnUp} alt="наверх" />
        </button>
      )}
    </>
  )
}
