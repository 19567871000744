import React, { useState, useEffect, useCallback } from "react";
import Input from "../Elements/Input/Input";
import Button from "../Elements/Button/Button";
import Loading from "../../Loader/Loader";
import BC365Service from "../../../services/beautyclub365";
import { useUserContext } from "../../../context/UserContext";
import "./AllProducts.css";

export default AllProducts = () => {

	// setBasket

	const [email, setEmail] = useState('');
	const [errors, setErrors] = useState({});
	const [existsError, setExistsError] = useState(false);
	const [answerText, setAnswerText] = useState('');
	const [isDisabled, setIsDisabled] = useState(true);
	const { be } = errors;
	const { setBasket, products, defaultPrice, setDefaultPrice, userProfile } = useUserContext();
	const [isWaitingAnswer, setIsWaitingAnswer] = useState(false);
	const changePrice = useCallback((e) => {
		setDefaultPrice(e.target.getAttribute("data-price"));
		setBasket(
			{
				price: e.target.getAttribute("data-price"),
				name: e.target.getAttribute("data-product"),
				price_clear: e.target.getAttribute("data-cprice"),
				product_id: e.target.getAttribute("data-prid"),
				payment_desc: '',
			}
		);
		setIsDisabled(false);
	}, [
		setDefaultPrice,
		setBasket,
		defaultPrice,
		setIsDisabled,
	]);

	const db = new BC365Service();

	// Step 1. Sales funnel (open payment popup)
	useEffect(() => {
		const { fio, email } = userProfile;
		db.openPayment({
			first_name: fio,
			email,
		}).then((answer) => {
			//console.log(answer);
		});
	}, [

	]);

	return (
		<div className="allProductsWrapper">
			{
				isWaitingAnswer &&
				<div className="loaderWrapper">
					<Loading width={100} />
				</div>
			}
			{
				!isWaitingAnswer &&
				<>
					<h2 className="formTitle">Выберите тариф</h2>
					{
						products.length > 0 && products.map((product, key) => {
							//const checked = (key === 0)? 'checked' : null;
							return (
								<div key={'product-key-' + product[0].id} className="blockProduct">
									<div className="blockProductSub">
										<div className="blockProductSubRadio">
											<input
												
												type="radio" id={'product-' +  product[0]['id']} name="product" value={ product[0]['id']} className="prRadio"
												data-price={product[0].price}
												data-cprice={product[0].clear_price}
												data-prid={product[0]['id']}
												data-product={ 
													(product[0].name === 'Платная подписка (1 месяц)')? 
														'1 месяц (всё включено)' 
														: (product[0].name === 'Платная подписка (6 месяцев)')?
															'6 месяцев (всё включено)'
															: (product[0].name === 'Платная подписка (12 месяцев)')?
																'12 месяцев (всё включено)'
																: product[0].name
												}
												onChange={changePrice} />
											<label htmlFor={'product-' +  product[0]['id']} className="prLabel">
												{ 
													(product[0].name === 'Платная подписка (1 месяц)')? 
														'1 месяц (всё включено)' 
														: (product[0].name === 'Платная подписка (6 месяцев)')?
															'6 месяцев (всё включено)'
															: (product[0].name === 'Платная подписка (12 месяцев)')?
																'12 месяцев (всё включено)'
																: product[0].name
												}
											</label>
										</div>
										<span className="prPrice">Цена: <strong>{ product[0].price}</strong></span>
									</div>
									{ typeof product[1] !== 'undefined' && (
										<div className="blockProductSub">
											<div className="blockProductSubRadio">
												<input type="radio" id={'product-' +  product[1]['id']} name="product" value={product[1]['id']} className="prRadio"
												data-price={product[1].price}
												data-cprice={product[1].clear_price}
												data-prid={product[1]['id']}
												data-product={ 
													(product[1].name === 'Платная подписка (1 месяц) - ТОЛЬКО С АННЕТТ')? 
														'1 месяц (только Аннет)' 
														: (product[1].name === 'Платная подписка (6 месяцев) - ТОЛЬКО С АННЕТТ')?
															'6 месяцев (только Аннет)'
															: (product[1].name === 'Платная подписка (12 месяцев) - ТОЛЬКО С АННЕТТ')?
																'12 месяцев (только Аннет)'
																: product[1].name
												}
												onChange={changePrice} />
												<label htmlFor={'product-' + product[1]['id']} className="prLabel">
													{ 
														(product[1].name === 'Платная подписка (1 месяц) - ТОЛЬКО С АННЕТТ')? 
															'1 месяц (только Аннет)' 
															: (product[1].name === 'Платная подписка (6 месяцев) - ТОЛЬКО С АННЕТТ')?
																'6 месяцев (только Аннет)'
																: (product[1].name === 'Платная подписка (12 месяцев) - ТОЛЬКО С АННЕТТ')?
																	'12 месяцев (только Аннет)'
																	: product[1].name
													}
												</label>
											</div>
											<span className="prPrice">Цена: <strong>{ product[1].price}</strong></span>
										</div>
									)}
								</div>
							)
						})
					}
					<label htmlFor="promo" className="promoLabel">Ваш промокод (если есть)</label>
					<div className="promoWrapper">
						<input id="promo" type = "text" placeholder="Введите промокод" className="promoInput" />
						<span className="promoButton">Применить</span>
					</div>
					<span className="finalPrice">К оплате: {defaultPrice}</span>
					<Button 
						type="button" 
						value="Перейти к оплате" 
						fields={{email}} 
						setIsWaitingAnswer={setIsWaitingAnswer}
						method="buy"
						validate={setErrors}
						existsErrors={setExistsError}
						setAnswerText={setAnswerText}
						disabled={isDisabled}
					/>
				</>
			}
		</div>
	);
}
