import React, { useEffect, useState } from "react";
// import PaymentBlockMobile from "../PaymentBlockMobile/PaymentBlockMobile";
import { usePopupContext } from "../../context/PopupContext";
import { useUserContext } from "../../context/UserContext";
import CheckEmail from "../Forms/CheckEmail/CheckEmail";
import AllProducts from "../Forms/AllProducts/AllProducts";
import "./PaymentBlock.css";
import AllTariffDetailPopup from "../Forms/TariffDetailPopUp/AllTariffDetailPopUp";
import BasicTariffDetailPopup from "../Forms/TariffDetailPopUp/BasicTariffDetailPopup";
import AnnettTariffDetailPopup from "../Forms/TariffDetailPopUp/AnnettTariffDetailPopup";
import AllTariffPayment from "../Forms/AllProducts/AllTariffPayment";
import AnnettTariffPayment from "../Forms/AllProducts/AnnettTariffPayment";
import BasicTariffPayment from "../Forms/AllProducts/BasicTariffPayment";

function PaymentBlock(props) {
  const { innoRef } = props;

  // function useWindowSize() {
  //   const [windowSize, setWindowSize] = useState({
  //     width: undefined,
  //     height: undefined,
  //   });
  //   useEffect(() => {
  //     function handleResize() {
  //       setWindowSize({
  //         width: window.innerWidth,
  //         height: window.innerHeight,
  //       });
  //     }
  //     window.addEventListener("resize", handleResize);
  //     handleResize();
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []);
  //   return windowSize;
  // }

  // const size = useWindowSize();

  const { setActive, setContent, setFromTrialPopup } = usePopupContext();
  const { userProfile, setIsCertificate, products } = useUserContext();
  const joinToUs = () => {
    const { online } = userProfile;
    setActive(true);
    if (online) {
      setIsCertificate(false);
      setContent(<AllProducts />);
    } else {
      setContent(<CheckEmail />);
    }
  };

  const showBasicTariffDetail = (e) => {
    e.preventDefault();
    setContent("");
    setActive(true);
    setContent(<BasicTariffDetailPopup />);
  };
  const buyBasicTariff = () => {
    const { online } = userProfile;
    setActive(true);
    setFromTrialPopup(false);
    if (online) {
      setIsCertificate(false);
      setContent(<BasicTariffPayment />);
    } else {
      setContent(<CheckEmail />);
    }
  };

  const showAnnettTariffDetail = (e) => {
    e.preventDefault();
    setContent("");
    setActive(true);
    setContent(<AnnettTariffDetailPopup />);
  };
  const buyAnnettTariff = () => {
    const { online } = userProfile;
    setActive(true);
    if (online) {
      setIsCertificate(false);
      setContent(<AnnettTariffPayment />);
    } else {
      setContent(<CheckEmail />);
    }
  };

  const showAllTariffDetail = (e) => {
    e.preventDefault();
    setContent("");
    setActive(true);
    setContent(<AllTariffDetailPopup />);
  };
  const buyAllTariff = () => {
    const { online } = userProfile;
    setActive(true);
    if (online) {
      setIsCertificate(false);
      setContent(<AllTariffPayment />);
    } else {
      setContent(<CheckEmail />);
    }
  };

  // if (size.width > 500) {
  return (
    <div className="payment-block" id="payment_block">
      <div ref={innoRef} className="payment-pointBlock"></div>
      <div className="payment-block-h1">
        Сколько стоит билет в вашу новую жизнь?
      </div>
      <div className="payment-block-container">
        <div className="payment-block-box">
          <div className="payment-block-box-header payment-block-box-header-green">
            <h1 className="payment-block-box-header-h1">
              тариф<br></br>«БАЗОВЫЙ»
            </h1>
          </div>
          <div className="payment-block-box-content">
            <h2 className="payment-block-box-h2">В тариф входит:</h2>
            <p className="payment-block-box-p">
            До 3 эфиров Меланнетт еженедельно. Йога, пилатес, кулинария, хэндмейд косметика
            </p>
            <div className="payment-block-price-box">
              <h2 className="payment-block-box-h2">Стоимость</h2>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">1 месяц</div>
                <div className="payment-block-box-price">
                  {products[1] && products[1][0].price}
                </div>
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">6 месяцев</div>
                <div className="payment-block-box-price">
                  {products[2] && products[2][1].price}
                </div>
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">12 месяцев</div>
                <div className="payment-block-box-price">
                  {products[4] && products[4][0].price}
                </div>
              </div>
            </div>
            <button
              className="payment-block-more text-7 inter-medium-white-14px"
              onClick={showBasicTariffDetail}
            >
              подробнее
            </button>
            <button
              className="payment-block-join text-7 inter-medium-white-14px"
              onClick={buyBasicTariff}
            >
              Вступить в клуб
            </button>
          </div>
        </div>
        <div className="payment-block-box">
          <div className="payment-block-box-header payment-block-box-header-pink">
            <h1 className="payment-block-box-header-h1">
              тариф<br></br>«ТОЛЬКО АННЕТТ»
            </h1>
          </div>
          <div className="payment-block-box-content">
            <h2 className="payment-block-box-h2">В тариф входит:</h2>
            <p className="payment-block-box-p">
              Омолаживающая утренняя гимнастика и неповторимые «чуфырные» эфиры
              от Меланнетт.
            </p>
            <div className="payment-block-price-box">
              <h2 className="payment-block-box-h2">Стоимость</h2>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">1 месяц</div>
                <div className="payment-block-box-price">
                  {products[0] && products[0][1].price}
                </div>
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">6 месяцев</div>
                <div className="payment-block-box-price">
                  {products[2] && products[2][0].price}
                </div>
              </div>
              <div className="payment-block-box-economy">
                Экономия: {products[2] && products[2][0].economy} руб.
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">12 месяцев</div>
                <div className="payment-block-box-price">
                  {products[3] && products[3][1].price}
                </div>
              </div>
              <div className="payment-block-box-economy">
                Экономия: {products[3] && products[3][1].economy} руб.
              </div>
            </div>
            <button
              className="payment-block-more text-7 inter-medium-white-14px"
              onClick={showAnnettTariffDetail}
            >
              подробнее
            </button>
            <button
              className="payment-block-join text-7 inter-medium-white-14px"
              onClick={buyAnnettTariff}
            >
              Вступить в клуб
            </button>
          </div>
        </div>
        <div className="payment-block-box">
          <div className="payment-block-box-header payment-block-box-header-purple">
            <h1 className="payment-block-box-header-h1">
              тариф<br></br>«ВСЕ ВКЛЮЧЕНО»
            </h1>
          </div>
          <div className="payment-block-box-content">
            <h2 className="payment-block-box-h2">В тариф входит:</h2>
            <p className="payment-block-box-p">
              До 10 прямых эфиров в день от 20 + спикеров на различные тематики.
            </p>
            <div className="payment-block-price-box">
              <h2 className="payment-block-box-h2">Стоимость</h2>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">1 месяц</div>
                <div className="payment-block-box-price">
                  {products[0] && products[0][0].price}
                </div>
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">6 месяцев</div>
                <div className="payment-block-box-price">
                  {products[1] && products[1][1].price}
                </div>
              </div>
              <div className="payment-block-box-economy">
                Экономия: {products[1] && products[1][1].economy} руб.
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">12 месяцев</div>
                <div className="payment-block-box-price">
                  {products[3] && products[3][0].price}
                </div>
              </div>
              <div className="payment-block-box-economy">
                Экономия: {products[3] && products[3][0].economy} руб.
              </div>
            </div>
            <button
              className="payment-block-more text-7 inter-medium-white-14px"
              onClick={showAllTariffDetail}
            >
              подробнее
            </button>
            <button
              className="payment-block-join text-7 inter-medium-white-14px"
              onClick={buyAllTariff}
            >
              Вступить в клуб
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  // } else if (window.innerWidth < 500) {
  //   return <PaymentBlockMobile innoRef={innoRef} products={products} />;
  // }
}

export default PaymentBlock;
