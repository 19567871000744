import React, { useEffect, useState } from "react";
import Input from "../Elements/Input/Input";
import Button from "../Elements/Button/Button";
import Loading from "../../Loader/Loader";
import cp from "../../../img/cloudpayments.svg";
import tinkoffLogo from "../../../img/tinkoff.svg";
import pp from "../../../img/globus.svg";
import sbp from "../../../img/sbp.jpg";
import { PROXY_URL, CP_PUBLIK_KEY_RU, CP_PUBLIK_KEY_SNG } from "../../../shared/constants";
import { useUserContext } from "../../../context/UserContext";
import { fromRubToKZT } from "../../../shared/utils";
import Promocode from "../../Promocode/Promocode";
import "./Buy.css";

export default Buy = ({
	usePromocode,
}) => {
	const [email, setEmail] = useState('');
	const [isWaitingAnswer, setIsWaitingAnswer] = useState(false);
	const [errors, setErrors] = useState({
		email: '',
	});
	const [existsError, setExistsError] = useState(false);
	const [answerText, setAnswerText] = useState('');
	const { be } = errors;



	const { basketData, setOrder, userProfile, orderData } = useUserContext();

	const [isDisabled, setIsDisabled] = useState(true);
	const [isAgree, setIsAgree] = useState(false);
	const [isSelectedPS, setIsSelectedPS] = useState(false);
	const [isRus, setisRus] = useState(true);

	const setAgree = (e) => {
		setIsAgree(e.target.checked);
	};
	
	const [defaultPaySystem, setDefaultPaySystem] = useState(true);
	useEffect(() => {
		const randomDefaultCheckedPaysystem = () => {
			return "tinkoff_sbp";
		}
	
		const checkedPaysystem = randomDefaultCheckedPaysystem();
		setDefaultPaySystem(checkedPaysystem);
		if (checkedPaysystem !== 'cp-ru') {
			setOrder({
				...orderData,
				paysystem: 'TINKOFF_SBP',
			});
		}
	}, [
		
	]);

	const selectPS = (e) => {
		if (e.target.value === 'cp-ru') {
			setisRus(true);
		} else {
			setisRus(false);
		}
		setIsSelectedPS(true);

		let paysystem = '';
		setDefaultPaySystem(e.target.value);
		switch (e.target.value) {
			case 'tinkoff':
				paysystem = 'TINKOFF';
				break;

			case 'tinkoff_sbp':
				paysystem = 'TINKOFF_SBP';
				break;

			case 'cp-sng':
				paysystem = 'CLOUDPAYMENTS_KZ';
				break;

			case 'pp':
				paysystem = 'PAYINPAYOUT';
				break;

			case 'ps-ru':
				paysystem = 'PAYSELECTION';
				break;

			case 'cp-ru':
			default:
				paysystem = 'CLOUDPAYMENTS';
				break;
		}
		setOrder({
			...orderData,
			paysystem: paysystem
		});
	};

	useEffect(() => {
		if (setIsDisabled && isAgree) {
			setIsDisabled(false);
			// prepare order data
			const cur = (isRus)? 'RUB' : 'KZT';
			const keyCP = (isRus)? CP_PUBLIK_KEY_RU : CP_PUBLIK_KEY_SNG;
			const pr = (isRus)? Number(basketData['price_clear']) : Number(fromRubToKZT(basketData['price_clear']));
			setOrder({
				...orderData,
				accountId: userProfile['login'],
				amount: pr,
				currency: cur,
				publicId: keyCP,
			});
		} else {
			setIsDisabled(true);
		}
	}, [
		isAgree,
		isSelectedPS,
	]);
	

	return (
		<div className="buyWrapper">
			{
				isWaitingAnswer &&
				<div className="loaderWrapper">
					<Loading width={100} />
				</div>
			}
			{
				!isWaitingAnswer &&
				<>
					<h2 className="formTitle">Выберите способ оплаты</h2>

					<div className="cpWrapperMain">
						<div className="cpWrapper">
							<input
								checked={defaultPaySystem === 'tinkoff'}
								onChange={selectPS} 
								type="radio" 
								id="tinkoff" 
								name="payment" 
								value="tinkoff" 
								className="cpRadio" 
							/>
							<label htmlFor="tinkoff" className="cpLabel">
							<img src={tinkoffLogo} className="cpImage cpImage-tinkoff" />
								Российская федерация
							</label>
						</div>
						<div className="cpWrapper">
							<input 
								checked={defaultPaySystem === 'tinkoff_sbp'}
								onChange={selectPS} 
								type="radio" 
								id="tinkoff_sbp"
								name="payment" 
								value="tinkoff_sbp"
								className="cpRadio"
							/>
							<label htmlFor="tinkoff_sbp" className="cpLabel">
								<img src={sbp} className="cpImage" />
								Оплата по СБП
							</label>
						</div>
						<div className="cpWrapper1">
							<input 
								checked={defaultPaySystem === 'cp-sng'}
								onChange={selectPS} 
								type="radio" 
								id="cp-sng" 
								name="payment" 
								value="cp-sng" 
								className="cpRadio"
							/>
							<label htmlFor="cp-sng" className="cpLabel">
								<img src={cp} className="cpImage" />
								Страны СНГ
							</label>
						</div>
						<div className="cpWrapper1">
							<input
								checked={defaultPaySystem === 'pp'}
								onChange={selectPS}
								type="radio"
								id="pp"
								name="payment"
								value="pp"
								className="cpRadio"
							/>
							<label htmlFor="pp" className="cpLabel">
								<img src={pp} className="cpImage" />
								Весь мир
							</label>
						</div>
					</div>
					{usePromocode && <Promocode tariff={basketData.name} />}
					{!usePromocode && (
						<div className="cpWrapper2">
							<span className="cpPrice">К оплате {basketData.price}</span>
							<span className="cpTarriff"> Тариф {basketData.name}</span>
						</div>
					)}
					<div className="cpWrapper3">
						<input type="checkbox" id="agree" onChange={setAgree} />
						<label htmlFor="agree" className="cpAgree">Совершая оплату, вы соглашаетесь с условиями <a href={PROXY_URL + '/upload/public_offer_rf.pdf'} target="_blank">оферты</a> и <a href={PROXY_URL + '/terms-conditions/'} target="_blank">политикой конфиденциальности</a>
						</label>	
					</div>
					<Button 
						type="button" 
						value="Оплатить" 
						fields={{}} 
						setIsWaitingAnswer={setIsWaitingAnswer}
						method="finish"
						validate={setErrors}
						existsErrors={setExistsError}
						setAnswerText={setAnswerText}
						disabled={isDisabled}
					/>
					<span className="cpNotice">
						Подписка в клуб продлевается автоматически. 
						Средства с вашей карты будут списываться по завершении оплаченного периода в зависимости от выбранного вами тарифа.
						Оплачивая подписку, вы соглашаетесь с условиями <a href={PROXY_URL + '/upload/public_offer_rf.pdf'} target="_blank">оферты</a>.
						<br></br>Отменить подписку возможно в любое время в личном кабинете.
					</span>
				</>
			}
		</div>
	);
}
