import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { usePopupContext } from "../../../context/PopupContext";
import { useUserContext } from "../../../context/UserContext";
import { PROXY_URL } from "../../../shared/constants";
import BeSpeakerPopUp from "../../Forms/BeSpeakerPopUp/BeSpeakerPopUp";
import "./MobileMenu.css";

export default function MobileMenu(props) {
  const {
    goToFromMenu,
    WhatYouHaveBlockRef,
    WhatYouGetBlockRef,
    SecretOfSuccessRef,
    ChangeLifeRef,
    ResultsRef,
    PaymentRef,
    ExpertsRef,
    ScheduleRef,
    setIsOpenedMenu,
    openAuthRegisterPopup,
    userPublicData,
    logout,
  } = props;

  const { setActive, setContent } = usePopupContext();
  const { userProfile, setIsCertificate } = useUserContext();
  const isOnline = userProfile["online"];
  const buyCertificate = () => {
    const { online } = userProfile;
    setActive(true);
    if (online) {
      setIsCertificate(true);
      setContent(<AllCertificates />);
    } else {
      setContent(<CheckEmailCertificate />);
    }
  };
  const openBeSpeakerPopup = useCallback(() => {
    setActive(true);
    setContent(<BeSpeakerPopUp />);
  }, [setActive, setContent]);

  return (
    <div className="navbar_container_mobile">
      {!isOnline && (
        <div className="sepMenu">
          <a
            className="nav_link_mobile"
            onClick={(e) => {
              e.preventDefault();
              openAuthRegisterPopup();
            }}
          >
            Войти | Зарегистрироваться
          </a>
          {/*<a className="nav_link_mobile" onClick={buyCertificate}>
            Подарить сертификат
          </a> */}
          <a className="nav_link_mobile" onClick={openBeSpeakerPopup}>
            Стать спикером
          </a>
        </div>
      )}
      {isOnline && (
        <div className="sepMenu">
          <div className="sepMenuWrap">
            {userPublicData["typeAvatar"] === "image" && (
              <img
                src={PROXY_URL + "/upload" + userPublicData["avatar"]}
                alt="logo"
                className="logoAvatar"
              />
            )}
            {userPublicData["typeAvatar"] === "text" && (
              <span className="textAvatar">{userPublicData["avatar"]}</span>
            )}
            <span className="logoTitle">{userPublicData["name"]}</span>
          </div>
          <a
            className="nav_link_mobile strong"
            href={PROXY_URL + "/personal/subscription-feed/"}
          >
            Смотреть эфиры
          </a>
          <a className="nav_link_mobile" href={PROXY_URL + "/personal/"}>
            Личный кабинет
          </a>
          <a
            className="nav_link_mobile"
            href={PROXY_URL + "/become-a-speaker/"}
          >
            Хочу стать спикером
          </a>
          <Link className="nav_link_mobile" to="/market">Курсы</Link>
          <a
            className="nav_link_mobile"
            onClick={(e) => {
              logout(e);
            }}
          >
            Выйти
          </a>
        </div>
      )}
      <a
        className="nav_link_mobile"
        onClick={(e) => {
          goToFromMenu(e, WhatYouHaveBlockRef);
          setIsOpenedMenu(false);
        }}
      >
        Для кого
      </a>
      <a
        className="nav_link_mobile"
        onClick={(e) => {
          goToFromMenu(e, WhatYouGetBlockRef);
          setIsOpenedMenu(false);
        }}
      >
        Зачем нужен клуб
      </a>
      <a
        className="nav_link_mobile"
        onClick={(e) => {
          goToFromMenu(e, SecretOfSuccessRef);
          setIsOpenedMenu(false);
        }}
      >
        Направления
      </a>
      <a
        className="nav_link_mobile"
        onClick={(e) => {
          goToFromMenu(e, ChangeLifeRef);
          setIsOpenedMenu(false);
        }}
      >
        Что дает клуб
      </a>
      <a
        className="nav_link_mobile"
        onClick={(e) => {
          goToFromMenu(e, ResultsRef);
          setIsOpenedMenu(false);
        }}
      >
        Результаты участниц
      </a>
      <a
        className="nav_link_mobile"
        onClick={(e) => {
          goToFromMenu(e, PaymentRef);
          setIsOpenedMenu(false);
        }}
      >
        Тарифы
      </a>
      <a
        className="nav_link_mobile"
        onClick={(e) => {
          goToFromMenu(e, ExpertsRef);
          setIsOpenedMenu(false);
        }}
      >
        Спикеры
      </a>
      <a
        className="nav_link_mobile"
        onClick={(e) => {
          goToFromMenu(e, ScheduleRef);
          setIsOpenedMenu(false);
        }}
      >
        Расписание
      </a>
    </div>
  );
}
