import React from "react";
import Slider from "react-slick";
import "./slick.css"; 
import "./slick-theme.css";

import im1 from "../../img/рез-ты/image 11.png";
import im2 from "../../img/рез-ты/image 12.png";
import im3 from "../../img/рез-ты/image 13.png";
import im4 from "../../img/рез-ты/image 14.png";
import im5 from "../../img/рез-ты/image 15.png";
import im6 from "../../img/рез-ты/image 16.png";
import im7 from "../../img/рез-ты/image 17.png";
import im8 from "../../img/рез-ты/image 18.png";
import im9 from "../../img/рез-ты/image 19.png";

export default function PhotoCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      <div>
        <img className="slider-photo" src={im1} alt="image 11" />
      </div>
      <div>
        <img className="slider-photo" src={im4} alt="image 14" />
      </div>
      <div>
        <img className="slider-photo" src={im7} alt="image 17" />
      </div>
      <div>
        <img className="slider-photo" src={im2} alt="image 12" />
      </div>
      <div>
        <img className="slider-photo" src={im6} alt="image 16" />
      </div>
      <div>
        <img className="slider-photo" src={im9} alt="image 19" />
      </div>
      <div>
        <img className="slider-photo" src={im3} alt="image 13" />
      </div>
      <div>
        <img className="slider-photo" src={im5} alt="image 15" />
      </div>
      <div>
        <img className="slider-photo" src={im8} alt="image 18" />
      </div>
    </Slider>
  );
}
