import React, { useState } from "react";
import Frame61 from "../Frame61";
import uniqid from 'uniqid';
import { PROXY_URL } from "../../shared/constants";
import "./ScheduleBlock.css";

function ScheduleBlock(props) {
  const {
    line3,
    innoRef,
    shedule,
  } = props; 

  const [showAllShedule, setShowAllShedule] = useState(false);

  const showMore = () => {
    setShowAllShedule(true);
  };

  return (
    <div className="schedule_block" id="schedule_block">
      <div ref={innoRef} className="schedule-pointBlock"></div>
      <div className="text-18 montserrat-extra-bold-stratos-48px">
        Расписание клуба: занятия, лекции и полезные статьи
      </div>
      <div className="schedule_container">
        <div className="frame-65 margin-t-10">
          <div className="address-8 montserrat-extra-bold-stratos-24px">
            {shedule.todayDate}
          </div>
          <img className="line" src={line3} alt="Line 3" />
          {shedule.todayShedule.map((item) => {
            const id = uniqid();
						return (
							<Frame61 key={id}
                spanText1={item.time}
                spanText2={item.text}
                image={item.tag_imgs[0] ?? ''}
                frame60Props={{
                  className: '', 
                  spanText: item.speaker.name,
                  view: PROXY_URL + item.speaker.photo.src,
                }}
              />
						);
					})}
        </div>

        {showAllShedule && shedule.allShedule.map((sh, key) => {
          return (
            <div className="frame-65 margin-t-10">
              <div className="address-8 montserrat-extra-bold-stratos-24px">
                {sh.date}
              </div>
              <img className="line" src={line3} alt="Line 3" />
              {sh.content.map((item) => {
                return (
                  <Frame61 key={item.time}
                    spanText1={item.time}
                    spanText2={item.text}
                    image={item.tag_imgs[0] ?? ''}
                    frame60Props={{
                      className: '', 
                      spanText: item.speaker.name,
                      view: PROXY_URL + item.speaker.photo.src,
                    }}
                  />
                );
              })}
            </div>
          );
        })}

        {!showAllShedule && 
        <div className="btn_simple-9">
          <div className="text_label-5 inter-medium-white-14px" onClick={showMore}>
            Посмотреть всё расписание
          </div>
        </div>
        }
      </div>
    </div>
  );
}

export default ScheduleBlock;
