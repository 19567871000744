import React from "react";
import "./TariffDetailPopUp.css";

function AnnettTariffDetailPopup() {
  return (
    <div className="tariffDetail-container">
      <h1 className="tariffDetail-h1">Тариф «ТОЛЬКО АННЕТТ»</h1>
      <p className="tariffDetail-description">
        Начните утро с Аннетт! В прямом эфире или записи вы вместе выполняете
        самые крутые и проверенные упражнения и массажные техники. Это идеальная
        возможность для участников марафонов укрепить полученные результаты и
        улучшить их! <br />
        <br />
        Занимаясь с Аннетт вы легко введёте в привычку ежедневно поддерживать
        здоровье и молодость лица и тела. И принцип «Главное - делать!» станет
        вашим девизом на все сто! <br />
        <br />А ещё вы станете участниками уникальных Чуфырных эфиров и
        медитаций Аннетт. Бытовая магия и работа с подсознанием откроют перед
        вами новые возможности👌
      </p>
    </div>
  );
}

export default AnnettTariffDetailPopup;
