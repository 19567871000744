import React from "react";
import "./CommunitySupportBlock.css";
import phoneCommunity from "../../img/MainContent/iphone.png";

function CommunitySupportBlock(props) {
  const { rectangle41, rectangle40, rectangle42 } = props;

  return (
    <>
      <img className="rectangle-41" src={rectangle41} alt="Rectangle 41" />
      <div className="community-support_block">
        {/* <img className="rectangle-4-2" src={rectangle40} alt="Rectangle 40" /> */}
        {/* <div className="rectangle-39"></div> */}
        <div className="community-support-container">
          <div className="overlap-group9">
            <p className="text-68 montserrat-extra-bold-white-48px">
              <span className="text-68-pink montserrat-extra-bold-hollywood-cerise-48px">
                Сообщество единомышленниц{" "}
              </span>
              <span className="text-68-white montserrat-extra-bold-white-48px">
                поможет вам не сворачивать с выбранного пути
              </span>
            </p>
            <p className="text-69 inter-normal-white-18px">
              Коллективная поддержка — лучшая система мотивации. У вас
              наконец-то получится поменять образ жизни и выработать новые
              привычки
            </p>
          </div>
          <div className="overlap-group1-1">
            <img className="phone-community-img" src={phoneCommunity} alt="" />
          </div>
        </div>
        <img className="rectangle-4-2" src={rectangle42} alt="Rectangle 42" />
      </div>
    </>
  );
}

export default CommunitySupportBlock;
