import React from "react";
import "./WhatYouHaveBlock.css";

function WhatYouHaveBlock(props) {

  const {
    clubForYouMainText,
    clubForYouListText,
    innoRef,
  } = props;

  return (
    <div className="what-you-have_block" id="what-you-have_block">
      <div ref={innoRef} className="what-you-have-pointBlock"></div>
      <h1 className="text-108 montserrat-extra-bold-stratos-48px">
        {clubForYouMainText}
      </h1>
      <div className="what-you-have_container">
        <div className="frame-377">
          <div className="what-you-have-box">
            <div className="rectangle-52"></div>
            <div className="text-123 montserrat-extra-bold-stratos-24px">
              {clubForYouListText[0].desc}
            </div>
            <div className="text-124 inter-normal-stratos-18px">
              {clubForYouListText[0].text}
            </div>
          </div>
          <div className="what-you-have-box">
            <div className="rectangle-52"></div>
            <div className="text-123 montserrat-extra-bold-stratos-24px">
              {clubForYouListText[1].desc}
            </div>
            <div className="text-124 inter-normal-stratos-18px">
              {clubForYouListText[1].text}
            </div>
          </div>
          <div className="what-you-have-box">
            <div className="rectangle-52"></div>
            <div className="text-123 montserrat-extra-bold-stratos-24px">
              {clubForYouListText[2].desc}
            </div>
            <div className="text-124 inter-normal-stratos-18px">
              {clubForYouListText[2].text}
            </div>
          </div>
        </div>
        <div className="frame-377">
          <div className="what-you-have-box">
            <div className="rectangle-52"></div>
            <div className="text-123 montserrat-extra-bold-stratos-24px">
             {clubForYouListText[3].desc}
            </div>
            <div className="text-124 inter-normal-stratos-18px">
              {clubForYouListText[3].text}
            </div>
          </div>
          <div className="what-you-have-box">
            <div className="rectangle-52"></div>
            <div className="text-123 montserrat-extra-bold-stratos-24px">
              {clubForYouListText[4].desc}
            </div>
            <div className="text-124 inter-normal-stratos-18px">
              {clubForYouListText[4].text}
            </div>
          </div>
          <div className="what-you-have-box">
            <div className="rectangle-52"></div>
            <div className="text-123 montserrat-extra-bold-stratos-24px">
              {clubForYouListText[5].desc}
            </div>
            <div className="text-124 inter-normal-stratos-18px">
              {clubForYouListText[5].text}
            </div>
          </div>
        </div>
        <div className="frame-377" style={{marginBottom: '0'}}>
          <div className="what-you-have-box">
            <div className="rectangle-52"></div>
            <div className="text-123 montserrat-extra-bold-stratos-24px">
              {clubForYouListText[6].desc}
            </div>
            <div className="text-124 inter-normal-stratos-18px">
              {clubForYouListText[6].text}
            </div>
          </div>
          <div className="what-you-have-box">
            <div className="rectangle-52"></div>
            <div className="text-123 montserrat-extra-bold-stratos-24px">
              {clubForYouListText[7].desc}
            </div>
            <div className="text-124 inter-normal-stratos-18px">
              {clubForYouListText[7].text}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatYouHaveBlock;
