import React, { useState, useEffect, useCallback } from "react";
import Button from "../Elements/Button/Button";
import Loading from "../../Loader/Loader";
import BC365Service from "../../../services/beautyclub365";
import { useUserContext } from "../../../context/UserContext";
import "./AllProducts.css";

function AllTariffPayment() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [existsError, setExistsError] = useState(false);
  const [answerText, setAnswerText] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const { setBasket, products, defaultPrice, setDefaultPrice, userProfile } =
    useUserContext();
  const [isWaitingAnswer, setIsWaitingAnswer] = useState(false);
  const changePrice = useCallback(
    (e) => {
      setDefaultPrice(e.target.getAttribute("data-price"));
      setBasket({
        price: e.target.getAttribute("data-price"),
        name: e.target.getAttribute("data-product"),
        price_clear: e.target.getAttribute("data-cprice"),
        product_id: e.target.getAttribute("data-prid"),
        payment_desc: '',
      });
      setIsDisabled(false);
    },
    [setDefaultPrice, setBasket, defaultPrice, setIsDisabled]
  );
  const db = new BC365Service();
  useEffect(() => {
    const { fio, email } = userProfile;
    db.openPayment({
      first_name: fio,
      email,
    }).then((answer) => {
      //console.log(answer);
    });
  }, []);
  return (
    <div className="allProductsWrapper">
      {isWaitingAnswer && (
        <div className="loaderWrapper">
          <Loading width={100} />
        </div>
      )}
      {!isWaitingAnswer && (
        <>
          <h2 className="allProductsFormTitle">
            Выберите вариант подписки на тариф{" "}
            <span id="pink-text">
              "{products[0] && products[0][0].short_name}"
            </span>
          </h2>
          <div className="blockProduct">
            <div className="blockProductSub">
              <div className="blockProductSubRadio">
                <input
                  id={"product-" + products[0][0].id}
                  className="prRadio"
                  type="radio"
                  name="product"
                  data-price={products[0][0].price}
                  value={products[0][0].id}
                  data-prid={products[0][0].id}
                  data-cprice={products[0][0].clear_price}
                  onChange={changePrice}
                />
                <label
                  htmlFor={"product-" + products[0][0].id}
                  className="prLabel"
                >
                  1 месяц
                </label>
              </div>
              <span className="prPrice">
                Цена: <strong>{products[0] && products[0][0].price}</strong>
              </span>
            </div>
            <div className="blockProductSub">
              <div className="blockProductSubRadio">
                <input
                  className="prRadio"
                  id={"product-" + products[1][1].id}
                  type="radio"
                  name="product"
                  data-price={products[1][1].price}
                  value={products[1][1].id}
                  data-prid={products[1][1].id}
                  data-cprice={products[1][1].clear_price}
                  onChange={changePrice}
                />
                <label
                  htmlFor={"product-" + products[1][1].id}
                  className="prLabel"
                >
                  6 месяцев
                </label>
              </div>
              <span className="prPrice">
                Цена: <strong>{products[1] && products[1][1].price}</strong>
              </span>
            </div>
            <div className="blockProductSub">
              <div className="blockProductSubRadio">
                <input
                  className="prRadio"
                  id={"product-" + products[3][0].id}
                  type="radio"
                  name="product"
                  value={products[3][0].id}
                  data-price={products[3][0].price}
                  data-prid={products[3][0].id}
                  data-cprice={products[3][0].clear_price}
                  onChange={changePrice}
                />
                <label
                  htmlFor={"product-" + products[3][0].id}
                  className="prLabel"
                >
                  12 месяцев
                </label>
              </div>
              <span className="prPrice">
                Цена: <strong>{products[3] && products[3][0].price}</strong>
              </span>
            </div>
          </div>
          <label htmlFor="promo" className="promoLabel">
            Ваш промокод (если есть)
          </label>
          <div className="promoWrapper">
            <input
              id="promo"
              type="text"
              placeholder="Введите промокод"
              className="promoInput"
            />
            <span className="promoButton">Применить</span>
          </div>
          <span className="finalPrice">К оплате: {defaultPrice}</span>
          <Button
            type="button"
            value="Перейти к оплате"
            fields={{ email }}
            setIsWaitingAnswer={setIsWaitingAnswer}
            method="buy"
            validate={setErrors}
            existsErrors={setExistsError}
            setAnswerText={setAnswerText}
            disabled={isDisabled}
          />
        </>
      )}
    </div>
  );
}

export default AllTariffPayment;
