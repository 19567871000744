import React, { useState, useEffect, useCallback } from "react";
import Input from "../Elements/Input/Input";
import Button from "../Elements/Button/Button";
import Loading from "../../Loader/Loader";
import BC365Service from "../../../services/beautyclub365";
import { useUserContext } from "../../../context/UserContext";
import "./AllCertificates.css";

export default AllCertificates = () => {

	// setBasket

	const [email, setEmail] = useState('');
	const [isWaitingAnswer, setIsWaitingAnswer] = useState(false);
	const [errors, setErrors] = useState({});
	const [existsError, setExistsError] = useState(false);
	const [answerText, setAnswerText] = useState('');
	const [products, setProducts] = useState([]);
	const [price, setPrice] = useState([]);
	const [isDisabled, setIsDisabled] = useState(true);
	const { be } = errors;
	const { setBasket } = useUserContext();

	const db = new BC365Service();

	useEffect(() => {
		setIsWaitingAnswer(true);
		db.getCertificates().then((answer) => {
			setIsWaitingAnswer(false);
			if (answer['products'].length > 0) {
				setPrice(answer['products'][0].price);
				const productPairs = answer['products'].reduce((result, value, index, array) => {
					if (index % 2 === 0) {
						result.push(array.slice(index, index + 2));
					}
					return result;
				}, []);
				setProducts(productPairs);
			}
		});	
	}, [
		setProducts,
	]);

	const changePrice = useCallback((e) => {
		setPrice(e.target.getAttribute("data-price"));
		setBasket(
			{
				price: e.target.getAttribute("data-price"),
				name: e.target.getAttribute("data-product"),
				price_clear: e.target.getAttribute("data-cprice"),
				product_id: e.target.getAttribute("data-prid"),
				payment_desc: '',
			}
		);
		setIsDisabled(false);
	}, [
		setPrice,
		setBasket,
		price,
		setIsDisabled,
	]);

	return (
		<div className="allProductsWrapper">
			{
				isWaitingAnswer &&
				<div className="loaderWrapper">
					<Loading width={100} />
				</div>
			}
			{
				!isWaitingAnswer &&
				<>
					<h2 className="formTitle">Выберите тариф</h2>
					{
						products.length > 0 && products.map((product, key) => {
							//const checked = (key === 0)? 'checked' : null;
							return (
								<div key={'product-key-' + product[0].id} className="blockProduct">
									<div className="blockProductSub">
										<div className="blockProductSubRadio">
											<input
												
												type="radio" id={'product-' +  product[0]['id']} name="product" value={ product[0]['id']} className="prRadio"
												data-price={product[0].price}
												data-cprice={product[0].clear_price}
												data-prid={product[0]['id']}
												data-product={product[0].name}
												onChange={changePrice} />
											<label htmlFor={'product-' +  product[0]['id']} className="prLabel">
												{product[0].name}
											</label>
										</div>
										<span className="prPrice">Цена: <strong>{ product[0].price}</strong></span>
									</div>
									<div className="blockProductSub">
										<div className="blockProductSubRadio">
											<input type="radio" id={'product-' +  product[1]['id']} name="product" value={product[1]['id']} className="prRadio"
											data-price={product[1].price}
											data-cprice={product[1].clear_price}
											data-prid={product[1]['id']}
											data-product={product[1].name}
											onChange={changePrice} />
											<label htmlFor={'product-' + product[1]['id']} className="prLabel">
												{product[1].name}
											</label>
										</div>
										<span className="prPrice">Цена: <strong>{ product[1].price}</strong></span>
									</div>
								</div>
							)
						})
					}
					<label htmlFor="promo" className="promoLabel">Ваш промокод (если есть)</label>
					<div className="promoWrapper">
						<input id="promo" type = "text" placeholder="Введите промокод" className="promoInput" />
						<span className="promoButton">Применить</span>
					</div>
					<span className="finalPrice">К оплате: {price}</span>
					<Button 
						type="button" 
						value="Перейти к оплате" 
						fields={{email}} 
						setIsWaitingAnswer={setIsWaitingAnswer}
						method="buy"
						validate={setErrors}
						existsErrors={setExistsError}
						setAnswerText={setAnswerText}
						disabled={isDisabled}
					/>
				</>
			}
		</div>
	);
}
