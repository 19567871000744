import React, { useState, useEffect } from 'react';
import './PromotionLine.css';

export default function PromotionLine() {
  const date = new Date('5 Jul 2023 00:00:00');

  const [finishTime] = useState(date.getTime());
  const [[diffDays, diffH, diffM, diffS], setDiff] = useState([0, 0, 0, 0]);
  const [tick, setTick] = useState(false);

  useEffect(() => {
    const diff = (finishTime - new Date()) / 1000;
    if (diff < 0) return; // время вышло
    setDiff([
      Math.floor(diff / 86400), // дни
      Math.floor((diff / 3600) % 24),
      Math.floor((diff / 60) % 60),
      Math.floor(diff % 60),
    ]);
  }, [tick, finishTime]);

  useEffect(() => {
    const timerID = setInterval(() => setTick(!tick), 1000);
    return () => clearInterval(timerID);
  }, [tick]);

  return (
    <div className="promotion-line-wrapper">
      <div className="promotion-line-text">
        <div className="wrap-1">
          🌟 🌟 🌟 специальное предложение: <b>на все тарифы</b>
          <span className="promotion-line-sale-text">скидка 60%</span> по коду:
          <span className="promotion-line-sale-text">START</span>
        </div>
        <div className="wrap-2">
          <span>{`осталось ${diffDays} дней ${diffH.toString().padStart(2, '0')}:${diffM
            .toString()
            .padStart(2, '0')}:${diffS.toString().padStart(2, '0')}`}</span>
          <button className="promotion-line-btn">активировать</button>
        </div>
      </div>
    </div>
  );
}
