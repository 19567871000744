import React, { useState, useEffect, useCallback } from "react";
import BC365Service from "../../services/beautyclub365";
import { useUserContext } from "../../context/UserContext";
import "./Promocode.css";

export default Promocode = ({
	tariff
}) => {
	
	const {
		userProfile,
		basketData,
		setBasket,
	  } = useUserContext();

	const [coupon, setCoupon] = useState('');
	const [statusButton, setStatusButton] = useState({
		default: true,
		waiting: false,
		done: false,
	});

	const getTextButtonByStatus = useCallback(() => {
		if (statusButton['waiting']) {
			return 'Подождите';
		}
		if (statusButton['done']) {
			return 'Активирован';
		}
		return 'Применить';
	}, [
		statusButton,
	]);

	const isActiveClass = useCallback(() => {
		if (statusButton['default']) {
			return 'promoButton';
		}
		return 'promoButton notActive';
	}, [
		statusButton,
	]);

	const writeCoupon = (e) => {
		setCoupon(e.target.value);
	};

	const getDiscount = useCallback((e) => {
		if (statusButton['default']) {
			const db = new BC365Service();
			const { product_id } = basketData;
			const { user_id } = userProfile;
			setStatusButton({
				default: false,
				waiting: true,
				done: false,
			});
			db.getDiscount(coupon, product_id, user_id).then((answer) => {
				if (answer['res'] > 0) {
					const priceAfterDiscount = basketData['price_clear'] - answer['res'];
					const formattedPrice = new Intl.NumberFormat('ru-RU', {currency: 'RUB' }).format(priceAfterDiscount);
					setBasket(
						{
							...basketData,
							price_clear: priceAfterDiscount,
							price: `${formattedPrice} руб.`,
						}
					);
					setStatusButton({
						default: false,
						waiting: false,
						done: true,
					});
				} else {
					setStatusButton({
						default: true,
						waiting: false,
						done: false,
					});
				}
			});
		}
	}, [
		coupon,
		statusButton,
		basketData,
	]);

	return (
		<>
		<div className="promoWrapper">
			<input id="promo" type = "text" placeholder="Введите промокод" className="promoInput" onChange={writeCoupon} />
			<span className={isActiveClass()} onClick={getDiscount}>{getTextButtonByStatus()}</span>
		</div>
		<div className="cpWrapper2">
			<span className="cpPrice">К оплате {basketData.price}</span>
			<span className="cpTarriff"> Тариф {tariff}</span>
		</div>
		</>
	);
}
					