import React, { useState } from 'react';
import { getImagesPaths } from '../../shared/utils';
import { usePopupContext } from '../../context/PopupContext';
import { useUserContext } from '../../context/UserContext';
import CheckEmail from '../Forms/CheckEmail/CheckEmail';
import AllProducts from '../Forms/AllProducts/AllProducts';
import WaitingTrialAnswer from '../Forms/WaitingTrialAnswer/WaitingTrialAnswer';
import BC365Service from '../../services/beautyclub365';
import './HeaderInfo.css';
import { useEffect } from 'react';
// import TrialPopUp from '../Forms/TrialPopUp/TrialPopUp';
import { TRY_SUBSON_5_DAYS_POPUP_TIMER } from '../../shared/constants';
import { TRY_SUBSON_5_DAYS_POPUP_TIMER_20 } from '../../shared/constants';
import { useExitIntent } from 'use-exit-intent';

function HeaderInfo(props) {
  const { headerContent, x1Props } = props;
  const { butterfly, rectangle14, rectangle15 } = x1Props;
  const { description, images, subMainText } = headerContent;

  const [ timerId, setTimerId ] = useState(0);
  let [ timerChain, setTimerChain ] = useState({
    0: false,
    1: false,
  });
  const [ countShowedTimerPopups, setCountShowedTimerPopups ] = useState(0);
  let { mainText, headText, headTextWithTimer, timer } = headerContent;
  let bannerText = headText;
  if (timer > 0 && headTextWithTimer !== '' && typeof headTextWithTimer !== 'undefined') {
    const currentTime = Math.ceil(Date.now() / 1000);
    bannerText = (currentTime > timer) ? headTextWithTimer : headText;
  }

  // prepare main text
  mainText =
    mainText && mainText.includes('#SUB_TEXT#')
      ? mainText.replaceAll(
          '#SUB_TEXT#',
          `<span class="span1">${subMainText} </span>`
        )
      : mainText;

  // prepare images
  let contentImages = getImagesPaths(images);

  const { 
    setActive, 
    setContent,
    wasClosedPopup,
    wasOpenedPopupBefore,
    anonymousCanGetTrial,
  } = usePopupContext();

  const { 
      userProfile, 
      setIsCertificate, 
      userCanUseTrial, 
      showTestDrive, 
  } = useUserContext();

  const joinToUs = () => {
    const { online } = userProfile;
    setActive(true);
    if (online) {
      /*if (userCanUseTrial) {
        setContent(<WaitingTrialAnswer />);
        const db = new BC365Service();
        // add trial and redirect to personal area
        db.setTrial().then((answer) => {
          const { result } = answer;
          if (result) {
            window.location.assign('/personal/');
          }
        });
      } else {
        setIsCertificate(false);
        setContent(<AllProducts />);
      }*/
      window.location.assign('/personal/subscription-feed/');
    } else {
      setContent(<CheckEmail />);
    }
  };

  const setTimerPopup = (sec) => {
    const countWorkedPopups = Object.values(timerChain).filter((el) => el).length;
    if (
      (wasClosedPopup && !wasOpenedPopupBefore) // after another popup
      || (countWorkedPopups === 0 && (!wasClosedPopup && !wasOpenedPopupBefore)) // after init app
      ) {
      const showPopupTimer = setTimeout(() => {
        // showTrialPopup();
        timerChain[countShowedTimerPopups] = true;
        setTimerChain({
          ...timerChain,
        });
        const nextTimer = countShowedTimerPopups + 1;
        setCountShowedTimerPopups(nextTimer);
        clearTimeout(showPopupTimer);
      }, sec);
      setTimerId(showPopupTimer);
    } else {
      clearTimeout(timerId);
    }
  };

  const canShowTimerPopupByStep = (step) => {
    const steps = Object.values(timerChain);
    const countPrevDone = steps.filter((el) => el).length;
    if (!timerChain[step] && countPrevDone === step) {
      return true;
    }
    return false;
  };

  // const showTrialPopup = () => {
  //   setContent(<TrialPopUp />);
  //   setActive(true);
  // };
  useEffect(() => {
    const { online } = userProfile;
    // if (showTestDrive && ((online && userCanUseTrial) || !online)) {
    if (showTestDrive && !online && anonymousCanGetTrial) {  
      if (canShowTimerPopupByStep(0)) {
        setTimerPopup(TRY_SUBSON_5_DAYS_POPUP_TIMER);
      }
      if (canShowTimerPopupByStep(1)) {
        setTimerPopup(TRY_SUBSON_5_DAYS_POPUP_TIMER_20);
      }
    }
  }, [
    showTestDrive,
    userCanUseTrial,
    wasClosedPopup,
    wasOpenedPopupBefore,
    timerChain,
    userProfile,
    anonymousCanGetTrial,
  ]);

  return (
    <>
      <div className="x1">
        <div className="rectangle-13"></div>
        <div className="headerInfoWrapper">
          <div className="headerInfoWrapperText">
            <h1 className="h1-x1">
              {/*<span className="h1-white-text montserrat-extra-bold-white-40px">
                  {mainText}
                </span>*/}
              {/*<span className="h1-pink-text">{subMainText}</span>*/}
              {/*<span className="h1-white-text montserrat-extra-bold-white-40px">
                  BeautyClub!
                </span>*/}
              { /*
              ===== HIDE BLOCK WITH BUTTON TRIAL START =====
              {<span className="headerinfo-bold-text" dangerouslySetInnerHTML={{__html: description}}></span>}
              <span className="h1-white-text">
                {' '}
                Весна в Beautyclub365!&nbsp;
              </span>
              <span className="h1-pink-text montserrat-extra-bold-white-40px">
                Скидки до 30% на подписку в клуб&nbsp;
              </span>
              <span className="h1-white-text"> и подарки</span>
              <span className="headerinfo-bold-text">
                Продлите подписку или купите сертификат со скидкой до 12 марта и
                выиграйте подарки от beauty365, ЦУМ и PRADA!
              </span>
            </h1>
            {showTestDrive && (
              <button
                className="testDrive_btn"
                onClick={(e) => showTrialPopup(e)}
              >
                <div className="testDrive_btn-text">
                  <div className="btn-text-bold inter-black-white-24px">
                    3 дня бесплатно
                  </div>
                </div>
              </button>
            )}
            {!showTestDrive && (
            <button className="testDrive_btn" onClick={joinToUs}>
              <div className="testDrive_btn-text">
                <div className="btn-text-bold inter-black-white-24px">
                  Присоединиться
                </div>
              </div>
            </button>
            )}
            ===== HIDE BLOCK WITH BUTTON TRIAL END =====
            */ }
                {/*<span className="headerinfo-bold-text" dangerouslySetInnerHTML={{__html: description}}></span>*/}
                <div dangerouslySetInnerHTML={{__html: bannerText}}></div>
              </h1>
              {/*<a
                className="testDrive_btn"
                href="https://beautyclub365.ru/lp/easy15x21/"
                target="_blank"
              >*/}
                { /*<div className="testDrive_btn-text">
                  <div className="btn-text-bold inter-black-white-24px">Перейти к марафону</div>
                </div> */ }
                {/*showTestDrive &&
                <div className="testDrive_btn-text">
                  <a href="https://beautyclub365.ru/personal/subscription-feed/" className="btn-text-bold inter-black-white-24px">Перейти к марафону</a>
                </div>
                }
                {!showTestDrive &&
                <button className="testDrive_btn" onClick={joinToUs}>
                  <div className="testDrive_btn-text">
                    <div className="btn-text-bold inter-black-white-24px">
                      Присоединиться
                    </div>
                  </div>
                </button>
                */}
              {/*</a>*/}
              { <button className="testDrive_btn" onClick={joinToUs}>
                <div className="testDrive_btn-text">
                  <div className="btn-text-bold inter-black-white-24px">
                    Присоединиться
                  </div>
                </div>
              </button> }
            {/* <button className="testDrive_btn">
                <a className="testDrive_btn-text btn-text-bold inter-black-white-24px" href="https://lp.beautyclub365.ru/sun">Присоединиться</a>
              </button> */}
            </div>
            <div className="headerInfoWrapperImages">
              <img className="x1-block-img" src={contentImages['banner']} alt="" />
            </div>
          </div>

        <div className="headerInfo-group">
          <div className="x1-first-results-block">
            <div
              className="h1-club-x1 montserrat-extra-bold-white-48px"
              id="for-who"
            >
              Круг единомышленниц, о котором вы всегда мечтали
            </div>
            <div className="frame-375">
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px" style={{height:'116px'}}>
                  Первый результат уже через 4 недели
                </div>
              </div>
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px" style={{height: '145px'}}>
                  Омоложение, <p style={{whiteSpace:'nowrap'}}>здоровье, спорт,</p> развитие, психология, творчество - <span style={{whiteSpace:'nowrap'}}>15+ направлений</span>
                </div>
              </div>
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px">
                  Вебинары, тренировки, тренинги и мастер-классы в прямом эфире
                </div>
              </div>
            </div>
            <div className="frame-374">
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px">
                  15 ТОП-экспертов на одной онлайн-платформе
                </div>
              </div>
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px">
                  От 3 до 7 эфиров в день: легко найти то, что будет вам по душе
                </div>
              </div>
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px">
                  Полезные привычки, которые останутся с вами на всю жизнь
                </div>
              </div>
            </div>
          </div>
        </div>

        <img className="rectangle-1" src={rectangle14} alt="Rectangle 14" />
        <img className="rectangle-1" src={rectangle15} alt="Rectangle 15" />
        <img className="butterfly" src={butterfly} alt="butterfly" />
      </div>
    </>
  );
}

export default HeaderInfo;
