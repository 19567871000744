import React from "react";
import "./TariffDetailPopUp.css";

function TariffDetailPopup() {
  return (
    <div className="tariffDetail-container">
      <h1 className="tariffDetail-h1">Тариф «ВСЕ ВКЛЮЧЕНО»</h1>
      <p className="tariffDetail-description">
        Это мощная прокачка вашего тела, причём, с огромным выбором
        возможностей. Йога, растяжка, фитнес, цигун, славянская гимнастика,
        танцевальные практики - скучно не будет! <br />И вы ничего не
        пропустите, ведь эфиры сохраняются👌 <br />
        <br />
        Но это далеко не все🙌 На этом тарифе нашего закрытого клуба вы всегда
        будете иметь доступ к интересным материалам, лекциям, мастер-классам по
        астрологии, психологии, развитию памяти, нейрографике, сможете общаться
        с экспертами и единомышленниками. <br />
        <br />
        Все это сделает вашу жизнь осознанной и полной впечатлений и новых
        навыков. <br />
        <br />
        <b style={{ fontWeight: "600" }}>«Все включено»</b> - для того, чтобы
        меняться, развиваться и быть довольней собой!
      </p>
    </div>
  );
}

export default TariffDetailPopup;
