import React from "react";
import "./TariffDetailPopUp.css";

function BasicTariffDetailPopup() {
  return (
    <div className="tariffDetail-container">
      <h1 className="tariffDetail-h1">Тариф «БАЗОВЫЙ»</h1>
      <p className="tariffDetail-description">
        Вытачивайте своё новое, упругое тело и постигайте таинство приготовления
        вкусной, полезной еды и экологичной косметики! <br /> <br />
        3 эфира в неделю омолаживающих гимнастик Аннетт вернут вашему телу
        подвижную спину, молодую осанку, красивые, точеные черты лица и гладкую
        кожу. <br /> <br />
        3 эфира йоги и пилатеса в неделю быстро превратят вас в подтянутых
        красоток со стабильной психикой и хорошей сопротивляемостью к стрессу. <br /> <br />
        Вы обретёте уникальное хобби и будете радовать себя и подруг косметикой собственного производства (1 эфир в неделю). А ещё освоите кулинарные изыски на каждый день и для торжественного случая (1 эфир в неделю).
      </p>
    </div>
  );
}

export default BasicTariffDetailPopup;
