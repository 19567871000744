import React from "react"; 
import Frame60 from "../Frame60";
import { PROXY_URL } from "../../shared/constants";
import "./Frame61.css";

function Frame61(props) {
  const { spanText1, spanText2, image, frame60Props } = props;
  return (
    <div className="frame-6">
      <div className="text-13 inter-normal-hollywood-cerise-24px">
        <span className="inter-normal-hollywood-cerise-24px">{spanText1}</span>
      </div>
      <div className="text-20 inter-normal-stratos-18px">
        <span className="inter-normal-stratos-18px">{spanText2}</span>
      </div>
      <img className={(image !== '')?'image-3':'image-3 opacity'} src={PROXY_URL + image} alt="image" />
      <Frame60 view={frame60Props.view} spanText={frame60Props.spanText} className={frame60Props.className} />
    </div>
  );
}

export default Frame61;
