import React from "react";
import { UserProvider } from "./context/UserContext";
import { PopupProvider } from "./context/PopupContext";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from "./pages/main/main";
import Courses from "./pages/main/courses";
import Payment from "./pages/main/payment";

function App() {
  return (
    <UserProvider>
      <PopupProvider>
        <Router>
          <Route path="/:path(|bc365)">
            <Main {...bc365Data} />
          </Route>
          <Route path="/react">
            <Main {...bc365Data} />
          </Route>
          <Route path="/market">
            <Courses />
          </Route>
          <Route path="/payment">
            <Payment />
          </Route>
        </Router>
      </PopupProvider>
    </UserProvider>
  );
}

export default App;

const x1Data = {
  rectangle14:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-14-1@2x.png",
  butterfly:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/butterfly-1@2x.png",
  rectangle15:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-15-1@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/vector-8-1@2x.png",
};

const whatYouGetBlockData = {
  whatyouget_Block:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-22-1@2x.png",
};

const subscriptionBlockData = {
  overlapGroup12:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-45-1@2x.png",
  rectangle43:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-43-1@2x.png",
  rectangle46:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-46-1@2x.png",
  rectangle44:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-44-1@2x.png",
  line7:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/line-7-1@2x.png",
  overlapGroup:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/vector-1-1@2x.png",
  image21:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-21-1@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/vector-9-1@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/vector-10-1@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/vector-11-1@2x.png",
};

const secretOfSuccessBlockData = {
  image5:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-5-1@2x.png",
  rectangle19:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-19-1@2x.png",
  rectangle20:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-20-1@2x.png",
  rectangle21:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-21-1@2x.png",
  rectangle22:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-22-1@2x.png",
  rectangle23:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-23-1@2x.png",
  rectangle24:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-24-1@2x.png",
  rectangle25:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-25-1@2x.png",
};

const individualProgrammBlockData = {
  image20:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-20-1@2x.png",
  x1: "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/--------1-2@2x.png",
  x2: "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/--------2-2@2x.png",
};

const resultsBlockData = {
  image11:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-11-1@2x.png",
  image14:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-14-1@2x.png",
  image12:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-12-1@2x.png",
  image16:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-16-1@2x.png",
  image17:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-17-1@2x.png",
  image19:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-19-1@2x.png",
  image13:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-13-1@2x.png",
  image15:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-15-1@2x.png",
  image18:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-18-1@2x.png",
};

const motivationBlockData = {
  image6:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-6-1@2x.png",
};

const communitySupportBlockData = {
  rectangle41:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-38-1@2x.png",
  rectangle40:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-49-1@2x.png",
  rectangle42:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-42-1@2x.png",
  image9:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-9-1@2x.png",
  image10:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-10-1@2x.png",
  image8:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-8-1@2x.png",
  appleIphone13ProMax2021Medium1:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/apple-iphone-13-pro-max-2021-medium-1-1@2x.png",
};

const frame601Data = {
  view: "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/---------5@2x.png",
  spanText: "Быстрова Софья",
  className: "",
};

const frame611Data = {
  image:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/---------4@2x.png",
  frame60Props: frame601Data,
};

const frame602Data = {
  view: "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/----------2@2x.png",
  spanText: "Татьяна Камадеева",
  className: "frame-60",
};

const frame612Data = {
  spanText1: "10:00",
  spanText2: "Бодифлекс",
  image:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/----------1@2x.png",
  frame60Props: frame602Data,
};

const frame603Data = {
  view: "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/-------1@2x.png",
  spanText: "Виктория Кипран",
  className: "frame-60",
};

const frame604Data = {
  view: "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/------1@2x.png",
  spanText: "Аннет",
  className: "frame-60",
};

const frame613Data = {
  spanText1: "08:00",
  spanText2: "Детская гимнастика",
  image:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/--------1@2x.png",
  frame60Props: frame604Data,
};

const frame605Data = {
  view: "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/-------------1@2x.png",
  spanText: "Строганова Ирина",
  className: "frame-60",
};

const frame614Data = {
  spanText1: "10:00",
  spanText2: "Пилатес",
  image:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/--------1@2x.png",
  frame60Props: frame605Data,
};

const frame606Data = {
  view: "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/----------3@2x.png",
  spanText: "Тамара Артёменко",
  className: "frame-60",
};

const frame615Data = {
  spanText1: "13:00",
  spanText2: "Zumba",
  image:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/------2@2x.png",
  frame60Props: frame606Data,
};

const scheduleBlockData = {
  line3:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/line-3-1@2x.png",
  line51:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/line-3-1@2x.png",
  line41:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/line-3-1@2x.png",
  image:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/-------------------1@2x.png",
  line52:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/line-3-1@2x.png",
  line42:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/line-3-1@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/line-3-1@2x.png",
  frame611Props: frame611Data,
  frame612Props: frame612Data,
  frame60Props: frame603Data,
  frame613Props: frame613Data,
  frame614Props: frame614Data,
  frame615Props: frame615Data,
};

const certificateBlockData = {
  rectangle38:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-38-1@2x.png",
  group:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/group-1@2x.png",
  image23:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-23-1@2x.png",
};

const beSpeakerBlockData = {
  rectangle49:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/rectangle-49-1@2x.png",
  image26:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-26-1@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/vector-12-1@2x.png",
  image24:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-24-1@2x.png",
  image25:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/image-25-1@2x.png",
};
const footerData = {
  mail: "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/mail-1@2x.png",
  phoneCall:
    "https://anima-uploads.s3.amazonaws.com/projects/6335a50db72f1a2d670abe6b/releases/6335afd01f9ca8e2d224ba5f/img/phone-call-1@2x.png",
};

const bc365Data = {
  x1Props: x1Data,
  whatYouGetBlockProps: whatYouGetBlockData,
  subscriptionBlockProps: subscriptionBlockData,
  secretOfSuccessBlockProps: secretOfSuccessBlockData,
  individualProgrammBlockProps: individualProgrammBlockData,
  resultsBlockProps: resultsBlockData,
  motivationBlockProps: motivationBlockData,
  communitySupportBlockProps: communitySupportBlockData,
  scheduleBlockProps: scheduleBlockData,
  certificateBlockProps: certificateBlockData,
  beSpeakerBlockProps: beSpeakerBlockData,
  footerProps: footerData,
};

window.onerror = function(msg, url, line, col, exception) {
  let params = new URLSearchParams();
  params.set('msg', msg);
  params.set('url', url);
  params.set('line', line);
  params.set('col', col);

  if (typeof exception === 'object' && exception !== null)
    params.set('stack', exception.stack);

  fetch('/local/ajax/js_error.php', {
    method: 'POST',
    body: params
  });
}
