import React, { useEffect, useState } from "react";
import "./VideoReview.css";
import BC365Service from "../../services/beautyclub365";

function VideoReview() {
  const db = new BC365Service();

  const [reviews, setReviews] = useState([]);
  const [visible, setVisible] = useState(3);
  const loadMoreVideoReviews = () => {
    setVisible((prevValue) => prevValue + 3)
  }

  useEffect(() => {
    //get video reviews
    db.getVideoReviews().then((data) => {
      setReviews(data);
    })
  }, [])

  return (
    <div className="video-reviews_container" id="video-reviews">
      <div className="text-11-1 montserrat-extra-bold-stratos-48px">
        Посмотрите, что говорят участницы клуба
      </div>
      <div className="video-container">
        {reviews.slice(0, visible).map((item, index) => {
          return (
            <div className="video-reviews-box" key={index}>
              <iframe
              className="video-reviews_block"
              src={item.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              ></iframe>
              <div style={{padding: '0 20px'}}>
                <p className="video-reviews-description">
                  "{item.description}"
                </p>
                <p className="video-reviews-name">{item.reviewer_name}</p>
              </div>
            </div>
          )
        })}
        {reviews.length > visible && 
          <button className="load-more-reviews" onClick={loadMoreVideoReviews}>Показать больше отзывов</button>
        }
      </div>
    </div>
  )
}

export default VideoReview;
